import React from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';

import HeaderSplash from '../components/home/header-splash';
import SectionSkills from '../components/home/section-skills';
import SectionAbout from '../components/home/section-about';
import SectionContact from '../components/home/section-contact';
import Footer from '../components/footer';
import Nav from '../components/nav';
import useDocumentTitle from '../util/use-document-title';

function HomePage(props) {
    useDocumentTitle('');
    const { hash } = useLocation();

    return (
        <div className='page'>
            <Nav noScroll={true} />
            {hash && <ScrollRestoration />}
            <HeaderSplash state={props.firstLoad ? null : 'collapsed'} />
            <SectionSkills />
            <SectionAbout />
            <SectionContact />
            <Footer />
        </div>
    );
}

export default HomePage;
