import React, { useState } from 'react';

import useResize from '../../util/use-resize';
import Masonry from '../../util/masonry';

import { TextBtn } from '../buttons';
import Breadcrumbs from '../breadcrumbs';
import { useSearchParams } from 'react-router-dom';

//---------------------------------------------------------------------

function GalleryContainer(props) {
    const [columns, setColumns] = useState(3);
    const [searchParams, setSearchParams] = useSearchParams();

    const onResize = () => {
        let mobileBreakpoint = 568;
        let tabletBreakpoint = 768;

        if (window.innerWidth <= mobileBreakpoint && columns !== 2)
            setColumns(2);
        else if (
            window.innerWidth > mobileBreakpoint &&
            window.innerWidth <= tabletBreakpoint &&
            columns !== 3
        )
            setColumns(3);
        else if (window.innerWidth > tabletBreakpoint && columns !== 4)
            setColumns(3);
    };

    const clearAllSelected = () => {
        let entries = Array.from(searchParams.entries());

        for (let i = 0; i < entries.length; i++)
            searchParams.delete(entries[i][0]);

        setSearchParams(searchParams);
    };

    useResize(onResize, true);

    return (
        <section className='gallery-container'>
            <div className='container'>
                <Breadcrumbs />
                <div className='section-heading with-btn'>
                    <h1>Gallery</h1>
                    <TextBtn
                        to='/projects'
                        text='Switch to Projects'
                        leftIcon='fa-solid fa-rotate'
                    />
                </div>
                {props.children.length === 0 && (
                    <div className='gallery-no-results'>
                        <div className='graphic' />{' '}
                        <p>Filter returned no results</p>{' '}
                        <button onClick={clearAllSelected}>
                            <i className='fa-solid fa-square-minus' />
                            Clear all Filters
                        </button>
                    </div>
                )}
                <Masonry colNo={columns}>{props.children}</Masonry>
            </div>
        </section>
    );
}

export default GalleryContainer;
