import React, { useRef, useEffect } from 'react';
import useClickedOutside from '../util/use-clicked-outside';
import useKeypress from '../util/use-keypress';
import useResize from '../util/use-resize';

function Sidebar(props) {
    // Toggle on click
    const handleClick = newState => {
        if (props.state === 'closed') props.setState('open');
        else if (props.state === 'open') props.setState('closed');
    };

    // Close on click outside
    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, () => {
        if (props.noCollapseOnDesktop)
            if (window.innerWidth >= props.breakpoint) return;

        props.setState('closed');
    });

    // Close on esc key
    useKeypress('Escape', () => {
        if (props.noCollapseOnDesktop)
            if (window.innerWidth >= props.breakpoint) return;

        props.setState('closed');
    });

    useResize(() => {
        if (window.innerWidth > 568 && props.state === 'open')
            document.body.classList.remove('modal-open');
    });

    useEffect(() => {
        if (window.innerWidth <= 568 && props.state === 'open')
            document.body.classList.add('modal-open');
        else if (window.innerWidth <= 568 && props.state === 'closed')
            document.body.classList.remove('modal-open');
    }, [props.state]);

    return (
        <div
            className={'sidebar ' + props.state + ' ' + props.className}
            ref={wrapperRef}>
            <button className='bubble' onClick={handleClick}>
                <i className={props.icon + ' action-open'} />
                <i className='fa-solid fa-times action-close' />
            </button>
            <div className='sidebar-content'>{props.children}</div>
        </div>
    );
}

export default Sidebar;
