import { useEffect } from 'react';

function useResize(callback, init) {
    useEffect(() => {
        const onResize = () => callback();

        window.addEventListener('resize', onResize);
        if (init) onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [callback, init]);
}

export default useResize;
