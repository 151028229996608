import React from 'react';

import selfPortrait from '../../_img/about-me-photo.png';

import { TextBtn } from '../buttons';

function SectionAbout(props) {
    return (
        <section className='section-about'>
            <div className='container'>
                <h2 className='tablet'>Who I Am</h2>
                <img className='hero' src={selfPortrait} alt="It's me!" />
                <h2 className='desktop'>Who I Am</h2>

                <p>
                    As an artist, writer, and all-around creative person, I love
                    bringing stories and characters to life.
                </p>

                <p>
                    I'm especially passionate about telling stories that{' '}
                    <em>matter</em>. I hope to use my skills to educate and
                    inspire positive change through storytelling, just like the
                    stories that had an impact on me as a child.
                </p>

                <p>
                    Take a look at my resume to see the work I've done in the
                    past. This website itself was a labor of love, and I hope
                    you enjoy exploring it! If you have a job you'd like to hire
                    me for, or if you just want to chat, contact me using the
                    form below.
                </p>
                <TextBtn
                    href='/resume.pdf'
                    text='Resume'
                    rightIcon='fa-solid fa-hand-point-right'
                />
            </div>
        </section>
    );
}

export default SectionAbout;
