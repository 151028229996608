import React from 'react';

import Nav from '../components/nav';
import Footer from '../components/footer';
import { TextBtn } from '../components/buttons';

import errorImg from '../_img/404.png';
import useDocumentTitle from '../util/use-document-title';

function Error404() {
    useDocumentTitle('404');
    return (
        <div className='page'>
            <Nav />
            <section className='section section-error-404'>
                <div className='container'>
                    <img
                        className='page-not-found'
                        src={errorImg}
                        alt='Page Not Found'
                    />
                    <div className='body'>
                        <p>
                            Either the page doesn't exist, or the cat got to it{' '}
                            <i className='fa-solid fa-cat' />
                        </p>
                        <TextBtn
                            to='/'
                            text='Return Home'
                            leftIcon='fa-solid fa-home'
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Error404;
