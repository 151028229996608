import React, { useState } from 'react';
import Sidebar from '../sidebar';
import Breadcrumbs from '../breadcrumbs';
import { Link } from 'react-router-dom';
import Masonry from '../../util/masonry';
import useResize from '../../util/use-resize';
import Lightbox from '../gallery/lightbox';
import { GalleryBtn } from '../buttons';

function Project(props) {
    const [sidebarState, setSidebarState] = useState(
        window.innerWidth > 1024 ? 'open' : 'closed'
    );
    return (
        <>
            <section className='project-container'>
                <div className='container'>
                    <Breadcrumbs />
                    <div className='section-heading'>
                        <h1>{props.title}</h1>
                        <h4>{props.description}</h4>
                        {props.notice && (
                            <div className='notice'>
                                <i className='fa-solid fa-circle-exclamation' />{' '}
                                {props.notice}
                            </div>
                        )}
                    </div>

                    {props.children}
                </div>
            </section>

            <Sidebar
                icon='fa-solid fa-bookmark'
                state={sidebarState}
                setState={setSidebarState}
                className='project-sidebar noCollapseOnLargeScreens'>
                <div className='heading'>
                    <h3>Outline</h3>
                </div>

                {props.sections &&
                    props.sections.length &&
                    props.sections.map((section, ind) => {
                        return (
                            <Link
                                key={ind}
                                to={'#' + section.id}
                                onClick={() => setSidebarState('closed')}>
                                <h6>{section.title}</h6>
                            </Link>
                        );
                    })}
            </Sidebar>
        </>
    );
}

//----------------------------------------------------------------------

function ProjectSectionSideBySide(props) {
    return (
        <section className='project-section-sidebyside' id={props.id}>
            <h2 className='tablet-title'>{props.title}</h2>
            <div className='left'>
                {props.titleOn === 'left' && (
                    <h2 className='desktop-title'>{props.title}</h2>
                )}
                {props.left}
            </div>
            <div className='right'>
                {props.titleOn === 'right' && (
                    <h2 className='desktop-title'>{props.title}</h2>
                )}
                {props.right}
            </div>
            {props.footer && (
                <div className='section-footer'>{props.footer}</div>
            )}
        </section>
    );
}

//----------------------------------------------------------------------

function ProjectSectionStacked(props) {
    return (
        <section className='project-section-stacked' id={props.id}>
            <h2>{props.title}</h2>
            <div className='top'>{props.top}</div>
            <div className='bottom'>{props.bottom}</div>
        </section>
    );
}

//----------------------------------------------------------------------

function ProjectImagesSideBySide(props) {
    return (
        <div className='project-images-side-by-side'>
            {props.children.map((child, ind) => {
                return (
                    <div key={ind} className='image-container'>
                        {child}
                    </div>
                );
            })}
        </div>
    );
}

//----------------------------------------------------------------------

function ProjectImagesStacked(props) {
    return <div className='project-images-stacked'>{props.children}</div>;
}

//----------------------------------------------------------------------

function ProjectLink(props) {
    return (
        <div className='project-link'>
            <a href={props.href} target='_blank' rel='noreferrer'>
                <h5>
                    {props.children}{' '}
                    <i className='fa-solid fa-square-up-right'></i>
                    {props.message && <span>{props.message}</span>}
                </h5>
            </a>
        </div>
    );
}

//----------------------------------------------------------------------

function ProjectLinks(props) {
    return (
        <section className='project-section-links' id='links'>
            <h2>Links</h2>
            {props.children}
        </section>
    );
}

//----------------------------------------------------------------------

function ProjectGallery(props) {
    const [columns, setColumns] = useState(3);

    const onResize = () => {
        let mobileBreakpoint = 568;
        let tabletBreakpoint = 768;

        if (window.innerWidth <= mobileBreakpoint && columns !== 2)
            setColumns(2);
        else if (
            window.innerWidth > mobileBreakpoint &&
            window.innerWidth <= tabletBreakpoint &&
            columns !== 3
        )
            setColumns(3);
        else if (window.innerWidth > tabletBreakpoint && columns !== 4)
            setColumns(3);
    };

    useResize(onResize, true);

    // launch lightbox
    const onItemClick = (id, val, ind) => {
        props.setLightbox({ id: id, item: val, ind: ind });
    };

    const onLightboxNext = () => {
        let newLightbox = props.lightbox.ind + 1;
        if (newLightbox === props.items.length) newLightbox = 0;

        props.setLightbox({
            id: props.items[newLightbox].id,
            item: props.items[newLightbox],
            ind: newLightbox
        });
    };

    const onLightboxPrev = () => {
        let newLightbox = props.lightbox.ind - 1;
        if (newLightbox < 0) newLightbox = props.items.length - 1;

        props.setLightbox({
            id: props.items[newLightbox].id,
            item: props.items[newLightbox],
            ind: newLightbox
        });
    };

    const onLightboxClose = () => {
        props.setLightbox({ id: null, item: null, ind: null });
    };

    return (
        <>
            <section
                className='project-gallery'
                id={props.id ? props.id : 'gallery'}>
                {props.title ? <h2>{props.title}</h2> : <h2>Gallery</h2>}
                {props.description}
                <Masonry colNo={columns}>
                    {props.items.map((item, ind) => {
                        return (
                            <GalleryBtn
                                src={item.thumb}
                                alt={item.title}
                                key={ind}
                                hasKey={ind}
                                handleClick={() =>
                                    onItemClick(item.id, item, ind)
                                }
                            />
                        );
                    })}
                </Masonry>
            </section>
            {props.lightbox.id !== null && props.lightbox.ind !== null && (
                <Lightbox
                    id={props.lightbox.id}
                    item={props.lightbox.item}
                    onNext={onLightboxNext}
                    onPrev={onLightboxPrev}
                    onClose={onLightboxClose}
                />
            )}
        </>
    );
}

export {
    Project,
    ProjectSectionSideBySide,
    ProjectSectionStacked,
    ProjectImagesSideBySide,
    ProjectImagesStacked,
    ProjectLink,
    ProjectLinks,
    ProjectGallery
};
