import React from 'react';
import { StickerBtn, TextBtn } from '../buttons';

import sticker01 from '../../_img/skills/skill-art-direction.png';
import sticker01b from '../../_img//skills/skill-art-direction-backing.png';
import sticker02 from '../../_img//skills/skill-illustration.png';
import sticker02b from '../../_img//skills/skill-illustration-backing.png';
import sticker03 from '../../_img/skills/skill-writing.png';
import sticker03b from '../../_img/skills/skill-writing-backing.png';
import sticker04 from '../../_img/skills/skill-coding.png';
import sticker04b from '../../_img/skills/skill-coding-backing.png';
import sticker05 from '../../_img/skills/skill-design-alt.png';
import sticker05b from '../../_img/skills/skill-design-alt-backing.png';
import sticker06 from '../../_img/skills/skill-animation.png';
import sticker06b from '../../_img/skills/skill-animation-backing.png';

function SectionSkills(props) {
    return (
        <section className='section-skills'>
            <div className='container'>
                <h2>What I Do</h2>
                <div className='stickers'>
                    <StickerBtn
                        className='sticker-01'
                        img={sticker01}
                        imgBack={sticker01b}
                        alt='Art Direction'
                        to='/projects'
                    />
                    <StickerBtn
                        className='sticker-02'
                        img={sticker02}
                        imgBack={sticker02b}
                        alt='Illustration'
                        to='/gallery'
                    />
                    <StickerBtn
                        className='sticker-03'
                        img={sticker03}
                        imgBack={sticker03b}
                        alt='Writing'
                        to='/projects'
                    />
                    <StickerBtn
                        className='sticker-04'
                        img={sticker04}
                        imgBack={sticker04b}
                        alt='Coding'
                        to='/projects'
                    />
                    <StickerBtn
                        className='sticker-05'
                        img={sticker05}
                        imgBack={sticker05b}
                        alt='Design'
                        to='/gallery'
                    />
                    <StickerBtn
                        className='sticker-06'
                        img={sticker06}
                        imgBack={sticker06b}
                        alt='Animation'
                        to='/gallery'
                    />
                </div>
                <div className='section-footer'>
                    <p>
                        This is just some of the many things I spend my time
                        doing, check out my gallery or projects to see more!
                    </p>
                    <TextBtn
                        to='/gallery'
                        text='Gallery'
                        rightIcon='fa-solid fa-hand-point-right'
                    />
                    <TextBtn
                        to='/projects'
                        text='Projects'
                        rightIcon='fa-solid fa-hand-point-right'
                    />
                </div>
            </div>
        </section>
    );
}

export default SectionSkills;
