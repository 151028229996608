import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Sidebar from '../sidebar';

function FilterCheckbox(props) {
    return (
        <div className='filter-checkbox'>
            <label htmlFor={props.name}>
                {props.checked ? (
                    <i className='fa-solid fa-square-check' />
                ) : (
                    <i className='fa-solid fa-square' />
                )}
                <span className='text'>{props.text}</span>
                <input
                    id={props.name}
                    type='checkbox'
                    checked={props.checked}
                    onChange={props.onChange}
                />
            </label>
        </div>
    );
}

//-----------------------------------------------------------------------------------------------------------------

function FilterSidebar(props) {
    // First, check if the url has searchParams string
    // Next, iterate through the default filters and see if there is a match in keys between filter and searchParams
    // If there is a match, get the filter options array, and the searchParams key-value
    // Split the searchParams key-value based on commas and convert into an array
    // For each split string, see if filter.options[string] is set to false. If so, set it to true

    // Now our filters should be initialized!

    // Next, watch for any changes in the checkboxes.
    // When a change happens, update the filter object and the searchParams

    // searchParams will look like: section1=option1,option2&section=option2
    // filter object will look like: [ {name: 'section1', options: [{name: 'option1', selected: true}, {name: .... etc.}]}]

    let collapsableBreakpoint = 1024;

    const [searchParams, setSearchParams] = useSearchParams();
    const [sidebarState, setSidebarState] = useState(
        window.innerWidth > collapsableBreakpoint ? 'open' : 'closed'
    );
    const [filters, setFilters] = useState([...props.filter]);

    // Update the filter based on query params
    useEffect(() => {
        let modFilters = [...filters];
        props.keys.forEach((key, ind) => {
            if (searchParams.has(key)) {
                let paramOptions = searchParams.get(key).split(',');

                if (paramOptions && paramOptions.length)
                    modFilters[ind].options.forEach(filterOption => {
                        if (paramOptions.includes(filterOption.name))
                            filterOption.selected = true;
                        else filterOption.selected = false;
                    });
            } else {
                modFilters[ind].options.forEach(option => {
                    option.selected = false;
                });
            }
        });

        setFilters(modFilters);
        // eslint-disable-next-line
    }, [searchParams, props.keys, setFilters]);

    // clear all searchParams
    const clearAllSelected = () => {
        filters.forEach(valFilter => {
            searchParams.delete(valFilter.name);
        });

        setSearchParams(searchParams);
    };

    // clear all the options in a single section, and thus also remove that key/value pair from searchParams
    const clearSectionSelected = ind => {
        searchParams.delete(filters[ind].name);

        setSearchParams(searchParams);
    };

    // set the option in the filter section to the opposite of its current value
    // a little trickier on the searchParams side, so just a couple of steps
    // first, check if the section name is a current key in the searchParams
    // --- if yes, then make a new combo string value by iterating through section options
    // --- if no, and we toggled the option to true, then add the new key to the searchParams and set value to option

    const toggleOption = (section, option) => {
        let sectionName = filters[section].name;
        let optionName = filters[section].options[option].name;

        if (searchParams.has(sectionName)) {
            let currentOptions = searchParams.get(sectionName).split(',');
            let hasOption = currentOptions.indexOf(optionName);

            if (hasOption === -1) currentOptions.push(optionName);
            else currentOptions.splice(hasOption, 1);

            if (currentOptions.length)
                searchParams.set(sectionName, currentOptions.join(','));
            else searchParams.delete(sectionName);
        } else searchParams.set(sectionName, optionName);

        setSearchParams(searchParams);
    };

    return (
        <Sidebar
            state={sidebarState}
            setState={setSidebarState}
            className='filter noCollapseOnLargeScreens'
            icon='fa-solid fa-filter'
            p>
            <div className='heading'>
                <h3>Filter</h3>
                <button className='clear-btn' onClick={clearAllSelected}>
                    <i className='fa-solid fa-square-minus' />
                    Clear All Filters
                </button>
            </div>

            {filters.map((val, ind) => {
                return (
                    <div className='filter-section' key={ind}>
                        <div className='filter-section-heading'>
                            <h5>{val.text}</h5>
                            <button
                                className='clear-btn'
                                onClick={() => clearSectionSelected(ind)}>
                                <i className='fa-solid fa-square-minus' />
                                Clear
                            </button>
                        </div>
                        <div className='filter-section-options'>
                            {val.options.map((optVal, optInd) => {
                                return (
                                    <FilterCheckbox
                                        key={optInd}
                                        name={optVal.name}
                                        text={optVal.text}
                                        checked={optVal.selected}
                                        onChange={() =>
                                            toggleOption(ind, optInd)
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </Sidebar>
    );
}

// ----------------------------------------------------------------------

// Props:
// -- data: The data containing all the items
// -- filter: The filter sections array

function FilteredContent(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    let shouldFilter = false;
    let filteredContent = [];

    // first, check if the search params has at least one of the filterable keys
    props.keys.forEach(val => {
        if (searchParams.has(val)) shouldFilter = true;
    });

    // query params filter
    if (shouldFilter === true && !props.filter) {
        props.data.forEach(item => {
            let shouldInclude = true;

            props.keys.forEach(key => {
                if (searchParams.has(key)) {
                    let validOptions = searchParams.get(key).split(',');

                    // for sections where the data has an array (i.e. skills)
                    if (Array.isArray(item[key]))
                        shouldInclude &= item[key].some(elem =>
                            validOptions.includes(elem)
                        );
                    // for sections where the data is just one value (i.e. type)
                    else if (
                        validOptions.length &&
                        validOptions.includes(item[key])
                    )
                        shouldInclude &= true;
                    // no matches but still filtered
                    else if (validOptions.length) shouldInclude &= false;
                }
            });

            if (shouldInclude) filteredContent.push(item);
        });
    }

    // specific hand-written filter in the format of [ {key: [value1, value2]}, {key: [value1, value2]} ]
    else if (props.filter) {
        // TODO: add this for projects gallery
    }

    // no filter
    else filteredContent = [...props.data];

    // init lightbox
    let lightboxInit = { id: null, item: null, filteredInd: null };

    if (searchParams.has('lightbox')) {
        lightboxInit.id = parseInt(searchParams.get('lightbox'));

        filteredContent.forEach((val, ind) => {
            if (val.id === lightboxInit.id) {
                lightboxInit.item = val;
                lightboxInit.filteredInd = ind;
            }
        });
    }

    const [lightbox, setLightbox] = useState(lightboxInit);

    // launch lightbox
    const onItemClick = (id, val, ind) => {
        searchParams.set('lightbox', id);
        setLightbox({ id: id, item: val, filteredInd: ind });
        setSearchParams(searchParams);
    };

    const onLightboxNext = () => {
        let newLightbox = lightbox.filteredInd + 1;
        if (newLightbox === filteredContent.length) newLightbox = 0;

        searchParams.set('lightbox', filteredContent[newLightbox].id);

        setLightbox({
            id: filteredContent[newLightbox].id,
            item: filteredContent[newLightbox],
            filteredInd: newLightbox
        });
        setSearchParams(searchParams);
    };

    const onLightboxPrev = () => {
        let newLightbox = lightbox.filteredInd - 1;
        if (newLightbox < 0) newLightbox = filteredContent.length - 1;

        searchParams.set('lightbox', filteredContent[newLightbox].id);

        setLightbox({
            id: filteredContent[newLightbox].id,
            item: filteredContent[newLightbox],
            filteredInd: newLightbox
        });
        setSearchParams(searchParams);
    };

    const onLightboxClose = () => {
        setLightbox({ id: null, item: null, ind: null });
        searchParams.delete('lightbox');
        setSearchParams(searchParams);
    };

    // TODO: Render something when no results from filter
    return (
        <>
            {React.createElement(
                props.containerComponent,
                { col: props.col },
                filteredContent.map((val, ind) => {
                    return props.itemComponent({
                        item: val,
                        src: val.thumb,
                        alt: val.title,
                        key: ind,
                        hasKey: ind,
                        handleClick: () => {
                            onItemClick(val.id, val, ind);
                        },
                        ...props.childProps
                    });
                })
            )}
            {lightbox.item !== null &&
                lightbox.ind !== null &&
                React.createElement(
                    props.lightboxComponent,
                    {
                        id: lightbox.id,
                        item: lightbox.item,
                        onNext: onLightboxNext,
                        onPrev: onLightboxPrev,
                        onClose: onLightboxClose
                    },
                    {}
                )}
        </>
    );
}

export { FilterSidebar, FilteredContent };
