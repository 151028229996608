import React, { useEffect } from 'react';

import useKeypress from '../../util/use-keypress';
import { CircleBtn, TextBtn } from '../buttons';

//---------------------------------------------------------------------

function Lightbox(props) {
    useEffect(() => {
        document.body.classList.add('modal-open');

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('lightbox-text-container');
        container.scrollTo(0, 0);
    }, [props.item]);

    // Close on click outside
    //const wrapperRef = useClickedInside(props.onClose);

    // Close on esc key
    useKeypress('Escape', () => {
        props.onClose();
    });

    // Next navigation
    useKeypress('ArrowRight', () => {
        props.onNext();
    });

    // Prev navigation
    useKeypress('ArrowLeft', () => {
        props.onPrev();
    });

    return (
        <div className='lightbox'>
            <button
                type='button'
                className='dark-overlay'
                onClick={props.onClose}
            />
            <CircleBtn
                className='prev'
                icon='fa-solid fa-chevron-left'
                type='button'
                onClick={props.onPrev}
            />
            <div className='content'>
                <button className='close' type='button' onClick={props.onClose}>
                    <i className='fa-solid fa-times' />
                </button>
                {(props.item.type === 'image' || props.item.type === 'gif') && (
                    <img
                        className='image'
                        src={props.item.img}
                        alt={props.item.alt}
                    />
                )}
                {props.item.type === 'video' && (
                    <video
                        className='video'
                        poster={props.item.poster}
                        width='100%'
                        controls
                        loop
                        src={props.item.img}
                    />
                )}

                {props.item.type === 'pdf' && (
                    <>
                        <object
                            aria-label={props.item.title}
                            className='pdf'
                            title={props.item.title}
                            type='application/pdf'
                            data={props.item.img + '#navpanes=0&scrollbar=0'}
                        />
                        <TextBtn
                            className='pdf-link'
                            href={props.item.img}
                            text='Open PDF'
                            target='_blank'
                            rightIcon='fa-solid fa-hand-point-right'>
                            {' '}
                        </TextBtn>
                    </>
                )}
                <div className='text-container' id='lightbox-text-container'>
                    <h3>{props.item.title}</h3>

                    {props.item.description}
                    <div className='meta'>
                        {props.item.skills.length > 0 && (
                            <div className='skills'>
                                <label>
                                    <i className='fa-solid fa-brain' />
                                    Skills:
                                </label>

                                {props.item.skills.map((val, ind) => {
                                    if (val === 'animation')
                                        return <span key={ind}>Animation</span>;
                                    if (val === '3d')
                                        return (
                                            <span key={ind}>3D Modeling</span>
                                        );

                                    if (val === 'art-direction')
                                        return (
                                            <span key={ind}>Art Direction</span>
                                        );
                                    if (val === 'character-design')
                                        return (
                                            <span key={ind}>
                                                Character Design
                                            </span>
                                        );
                                    if (val === 'material-design')
                                        return (
                                            <span key={ind}>
                                                Material Design
                                            </span>
                                        );
                                    if (val === 'design')
                                        return (
                                            <span key={ind}>
                                                Graphic Design
                                            </span>
                                        );

                                    if (val === 'branding')
                                        return <span key={ind}>Branding</span>;
                                    if (val === 'illustration')
                                        return (
                                            <span key={ind}>Illustration</span>
                                        );
                                    if (val === 'background-design')
                                        return (
                                            <span key={ind}>
                                                Background Design
                                            </span>
                                        );
                                    if (val === 'storyboarding')
                                        return (
                                            <span key={ind}>Storyboarding</span>
                                        );

                                    if (val === 'writing')
                                        return <span key={ind}>Writing</span>;

                                    if (val === 'screenwriting')
                                        return (
                                            <span key={ind}>Screenwriting</span>
                                        );

                                    return <span key={ind}></span>;
                                })}
                            </div>
                        )}
                        {props.item.tools.length > 0 && (
                            <div className='tools'>
                                <label>
                                    <i className='fa-solid fa-screwdriver-wrench' />
                                    Tools:
                                </label>

                                {props.item.tools.map((val, ind) => {
                                    if (val === 'procreate')
                                        return <span key={ind}>Procreate</span>;
                                    if (val === 'photoshop')
                                        return (
                                            <span key={ind}>
                                                Adobe Photoshop
                                            </span>
                                        );
                                    if (val === 'maker-tools')
                                        return (
                                            <span key={ind}>Maker Tools</span>
                                        );
                                    if (val === 'illustrator')
                                        return (
                                            <span key={ind}>
                                                Adobe Illustrator
                                            </span>
                                        );
                                    if (val === 'open-toonz')
                                        return <span key={ind}>OpenToonz</span>;
                                    if (val === 'after-effects')
                                        return (
                                            <span key={ind}>
                                                Adobe After Effects
                                            </span>
                                        );
                                    if (val === 'storyboard-pro')
                                        return (
                                            <span key={ind}>
                                                Storyboard Pro
                                            </span>
                                        );
                                    if (val === 'unity')
                                        return <span key={ind}>Unity</span>;
                                    if (val === 'opentoonz')
                                        return <span key={ind}>OpenToonz</span>;
                                    if (val === 'blender')
                                        return <span key={ind}>Blender</span>;
                                    if (val === 'aseprite')
                                        return <span key={ind}>Aseprite</span>;
                                    if (val === 'scenarist')
                                        return <span key={ind}>Scenarist</span>;

                                    return <span key={ind}></span>;
                                })}
                            </div>
                        )}
                        {props.item.date && (
                            <div className='date'>
                                <i className='fa-solid fa-calendar-days' />
                                {props.item.date.m}/{props.item.date.d}/
                                {props.item.date.y}
                            </div>
                        )}
                    </div>
                    {props.item.relatedProject && (
                        <TextBtn
                            text='Go To Project'
                            rightIcon='fa-solid fa-hand-point-right'
                            to={'/projects/' + props.item.relatedProject}
                        />
                    )}
                </div>
            </div>
            <CircleBtn
                className='next'
                icon='fa-solid fa-chevron-right'
                type='button'
                onClick={props.onNext}
            />
        </div>
    );
}

export default Lightbox;
