import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import Nav from '../../components/nav';
import {
    Project,
    ProjectGallery,
    ProjectLink,
    ProjectLinks,
    ProjectSectionSideBySide,
    ProjectSectionStacked
} from '../../components/projects/project';

import introWaterPour from '../../_img/projects/rising-tides/intro-water-pour.gif';
import gamesForOurFuture from '../../_img/projects/rising-tides/games-for-our-future.png';
import boardCloseup from '../../_img/projects/rising-tides/board-closeup.png';
import tiles from '../../_img/projects/rising-tides/tiles.gif';
import newDealCardsBrown from '../../_img/projects/rising-tides/new-deal-cards-brown.png';
import newDealCardsGreen from '../../_img/projects/rising-tides/new-deal-cards-green.png';
import disasterCards from '../../_img/projects/rising-tides/disaster-cards.png';

import dataRisingTides from '../../_data/data-rising-tides';
import useDocumentTitle from '../../util/use-document-title';

function RisingTides(props) {
    useDocumentTitle('Rising Tides');
    let sections = [
        { title: 'The Idea', id: 'idea' },
        { title: 'Games for Our Future', id: 'games-for-our-future' },
        { title: 'Gameplay', id: 'concept-art' },
        { title: 'Card Design', id: 'card-design' },
        { title: 'City Tiles', id: 'city-tiles' },
        { title: 'Impact', id: 'impact' },
        { title: 'Links', id: 'links' },
        { title: 'Gallery', id: 'gallery' }
    ];

    const random = () => {
        return Math.random() * 4 - 2;
    };

    const [lightbox, setLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    return (
        <div className='page'>
            <Nav />
            <Project
                title='Rising Tides'
                sections={sections}
                description='Material and graphic design for a water-based board game'>
                <ProjectSectionSideBySide
                    id={sections[0].id}
                    title={sections[0].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={introWaterPour}
                            alt={sections[0].title}
                        />
                    }
                    right={
                        <p>
                            During a 48 hour hackathon, I worked on a unique
                            board game to teach people about climate change and
                            the Green New Deal. Our team came up with the idea
                            of a game that utilized actual water, to show how
                            rising sea levels might affect the United States.
                            The challenges of making a{' '}
                            <b>water-based board game</b> were many, but in the
                            end my design contributions won us{' '}
                            <b>"Best Aesthetic"</b>!
                        </p>
                    }
                />
                <ProjectSectionSideBySide
                    id={sections[1].id}
                    title={sections[1].title}
                    titleOn='left'
                    right={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={gamesForOurFuture}
                            alt={sections[1].title}
                        />
                    }
                    left={
                        <p>
                            This game was made during the{' '}
                            <a
                                href='https://www.facebook.com/events/2267475306827968/'
                                target='blank'
                                rel='noreferrer'>
                                Games for our Future
                            </a>{' '}
                            hackathon, which challenged participants to make a
                            game that taught players about an aspect of climate
                            change or the Green New Deal. This deeply resonated
                            with me, as combining games and education was
                            something I{' '}
                            <Link
                                target='_blank'
                                to='/projects/supreme-courtship'>
                                had a passion for
                            </Link>
                            .
                        </p>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[2].id}
                    title={sections[2].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={boardCloseup}
                            alt={sections[2].title}
                        />
                    }
                    right={
                        <p>
                            Rising Tides functioned as a collaborative
                            resource-collection game, where players would
                            navigate the map attempting to pass Green New Deal
                            initiatives. Every turn, the game tub would be{' '}
                            <b>filled with more water</b>, resulting in some
                            cities becoming submerged and inaccessible.
                        </p>
                    }
                />

                <ProjectSectionStacked
                    id={sections[3].id}
                    title={sections[3].title}
                    top={
                        <p>
                            All of the game cards were laminated to make the
                            water-safe, and used colors and iconography to
                            convey the positive impact of Green New Deal
                            initiatives.
                        </p>
                    }
                    bottom={
                        <>
                            <img
                                src={newDealCardsBrown}
                                alt={sections[3].title}
                            />
                            <img
                                src={newDealCardsGreen}
                                alt={sections[3].title}
                            />
                        </>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[4].id}
                    title={sections[4].title}
                    titleOn='left'
                    left={
                        <p>
                            I had to be creative with the game board so that it
                            was easy to set up and, more importantly, held up
                            while underwater. I cut out acrylic hexagon tiles
                            which were then affixed to the board using a series
                            of washers and magnets. This let us represent
                            different elevations of cities as well, which was
                            important to accurately show how some cities were
                            more at risk than others.{' '}
                        </p>
                    }
                    right={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={tiles}
                            alt={sections[4].title}
                        />
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[5].id}
                    title={sections[5].title}
                    titleOn='right'
                    right={
                        <p>
                            It was wonderful to see how players reacted to the
                            game. Every round, players would draw a disaster
                            card and fill the game tub with more water,
                            resulting in the "destruction" of certain city
                            tiles. This elegantly connected the issues of
                            climate change to players' own lives, and made them
                            realize the importance of the Green New Deal
                            initiatives.
                        </p>
                    }
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={disasterCards}
                            alt={sections[5].title}
                        />
                    }
                />

                <ProjectLinks>
                    <ProjectLink href='https://medium.com/just-us-games-studio/rising-tides-making-a-board-game-about-climate-change-8fdd7d37d831'>
                        Write-Up About Making Rising Tides
                    </ProjectLink>
                    <ProjectLink href='https://www.youtube.com/watch?v=nJNZFAEpHAU'>
                        Rising Tides Gameplay Video
                    </ProjectLink>
                    <ProjectLink href='https://itch.io/jam/games-for-our-future-game-jam-2019/rate/422217'>
                        Rising Tides on Itch.io
                    </ProjectLink>
                </ProjectLinks>

                <ProjectGallery
                    items={dataRisingTides}
                    lightbox={lightbox}
                    setLightbox={setLightbox}
                />
            </Project>

            <Footer className='project-footer' />
        </div>
    );
}

export default RisingTides;
