import React from 'react';

function Masonry(props) {
    let children = React.Children.toArray(props.children);
    let cols = [];
    let colNo = props.colNo ? props.colNo : 4;

    for (let colInd = 0; colInd < colNo; colInd++) {
        let items = [];

        for (let galInd = colInd; galInd < children.length; galInd += colNo)
            items.push(children[galInd]);

        cols.push(
            <div className='masonry-column' key={colInd}>
                {items}
            </div>
        );
    }

    return <div className='masonry'>{cols}</div>;
}

export default Masonry;
