import React, { useState } from 'react';
import Footer from '../../components/footer';
import Nav from '../../components/nav';
import {
    Project,
    ProjectGallery,
    ProjectImagesSideBySide,
    ProjectLink,
    ProjectLinks,
    ProjectSectionSideBySide,
    ProjectSectionStacked
} from '../../components/projects/project';

import overview from '../../_img/gallery/thumbs/supreme-courtship-trailer.gif';
import logoBranding from '../../_img/projects/supreme-courtship/logo-branding.png';
import conceptArt from '../../_img/projects/supreme-courtship/concept-art.png';
import characterSprites from '../../_img/projects/supreme-courtship/character-sprites.png';
import characterSelect from '../../_img/projects/supreme-courtship/thumbs/character-select.gif';
import courtCase from '../../_img/projects/supreme-courtship/thumbs/court-case.gif';
import debate from '../../_img/projects/supreme-courtship/thumbs/debate.gif';
import map from '../../_img/projects/supreme-courtship/thumbs/map.gif';
import yasuFeedback from '../../_img/projects/supreme-courtship/yasu-feedback.png';
import documentarySnippet from '../../_img/projects/supreme-courtship/thumbs/documentary-snippet.gif';
import trailerSnippet from '../../_img/projects/supreme-courtship/trailer-snippet.gif';
import kickstarter from '../../_img/projects/supreme-courtship/kickstarter.png';

import dataSupremeCourtship from '../../_data/data-supreme-courtship';
import useDocumentTitle from '../../util/use-document-title';

function SupremeCourtship(props) {
    useDocumentTitle('Supreme Courtship');
    let sections = [
        { title: 'The Idea', id: 'idea' },
        { title: 'Logo and Branding', id: 'logo-branding' },
        { title: 'Concept Art', id: 'concept-art' },
        { title: 'Character Sprites', id: 'character-sprites' },
        { title: 'Game UI', id: 'game-ui' },
        { title: 'Managing Artists', id: 'managing-artists' },
        { title: 'Video Production', id: 'video-production' },
        { title: 'Kickstarter', id: 'kickstarter' },
        { title: 'Links', id: 'links' },
        { title: 'Gallery', id: 'gallery' }
    ];

    const random = () => {
        return Math.random() * 4 - 2;
    };

    const [lightbox, setLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    return (
        <div className='page'>
            <Nav />
            <Project
                title='Supreme Courtship'
                sections={sections}
                description='Art direction and illustration for an educational comedy video game'>
                <ProjectSectionSideBySide
                    id={sections[0].id}
                    title={sections[0].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={overview}
                            alt={sections[0].title}
                        />
                    }
                    right={
                        <p>
                            I was brought on as the <b>art director</b> for a
                            small indie game project with one goal:{' '}
                            <b>teach people about the Supreme Court</b> in a
                            fun, engaging way. The developer wanted to take{' '}
                            inspiration from the dating sim genre, thus the name
                            "Supreme Courtship".
                        </p>
                    }
                />

                <ProjectSectionStacked
                    id={sections[1].id}
                    title={sections[1].title}
                    bottom={<img src={logoBranding} alt={sections[1].title} />}
                    top={
                        <p>
                            Taking inspiration from popular dating sim games of
                            the time, I came up with our logo. Additionally, I
                            defined our project's fonts and designed branding
                            elements. These spear-headed the development of the
                            game's aesthetic.
                        </p>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[2].id}
                    title={sections[2].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={conceptArt}
                            alt={sections[2].title}
                        />
                    }
                    right={
                        <p>
                            As the art director, I had to make a lot of quick
                            concept art to convey the style and direction of the
                            game. While most of this was focused on characters
                            and locations, I also created a rough storyboard for
                            our in-game cinematic sequence, which you can{' '}
                            <a href='#gallery'>see in the gallery</a>.
                        </p>
                    }
                />

                <ProjectSectionStacked
                    id={sections[3].id}
                    title={sections[3].title}
                    top={
                        <p>
                            Not only did I get to illustrate the Supreme Court
                            justices (circa 2016) as young, stylish,
                            20-somethings, but I also got to design various
                            quirky side characters. And with each character
                            needing a set of emotes and poses, I ended up
                            drawing <b>nearly 400 unique sprites</b>!
                        </p>
                    }
                    bottom={
                        <img src={characterSprites} alt={sections[3].title} />
                    }
                />

                <ProjectSectionStacked
                    id={sections[4].id}
                    title={sections[4].title}
                    top={
                        <p>
                            Everything in our game needed to match the branding,
                            so I designed multiple different user interface
                            elements like dialogs, buttons, and menus. I then
                            implemented and animated these designs in the game's
                            engine, Unity.
                        </p>
                    }
                    bottom={
                        <>
                            <ProjectImagesSideBySide>
                                <img
                                    src={characterSelect}
                                    alt='Character Select'
                                />
                                <img src={courtCase} alt='Court Case' />
                            </ProjectImagesSideBySide>
                            <ProjectImagesSideBySide>
                                <img src={map} alt='Map' />
                                <img src={debate} alt='Debate' />
                            </ProjectImagesSideBySide>
                        </>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[5].id}
                    title={sections[5].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={yasuFeedback}
                            alt={sections[5].title}
                        />
                    }
                    right={
                        <p>
                            As the art director, I hired and managed three
                            artists. I had to describe to them the visual style
                            we were looking for, and guide them on how to
                            implement it, like in this feedback doc where I was
                            describing how to visually depict water.
                        </p>
                    }
                />

                <ProjectSectionStacked
                    id={sections[6].id}
                    title={sections[6].title}
                    top={
                        <p>
                            The game's trailer and Kickstarter video were put
                            together and edited by me using After Effects. I
                            animated custom transitions, made graphics, and even
                            added sound effects! I also worked in collaboration
                            with a videographer, helping with set design and
                            costumes.
                        </p>
                    }
                    bottom={
                        <>
                            <ProjectImagesSideBySide>
                                <img src={trailerSnippet} alt='Trailer' />
                                <img
                                    src={documentarySnippet}
                                    alt='Documentary'
                                />
                            </ProjectImagesSideBySide>
                        </>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[7].id}
                    title={sections[7].title}
                    titleOn='left'
                    right={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={kickstarter}
                            alt={sections[6].title}
                        />
                    }
                    left={
                        <p>
                            Along with everything else, I also helped write and
                            provide assets for the project's{' '}
                            <a
                                href='https://www.kickstarter.com/projects/justusgamesstudio/supreme-courtship-comedy-adventure-justice'
                                target='_blank'
                                rel='noreferrer'>
                                kickstarter
                            </a>
                            . While it ultimately did not reach its funding
                            goal, the project did get <b>495 backers</b> and was
                            even in Kickstarter's <b>"projects we love"</b>{' '}
                            category!
                        </p>
                    }
                />

                <ProjectLinks>
                    <ProjectLink href='https://www.supremecourtship.com/'>
                        Supreme Courtship Website
                    </ProjectLink>
                    <ProjectLink href='https://www.supremecourtship.com/demo'>
                        Supreme Courtship Game Demo
                    </ProjectLink>
                    <ProjectLink href='https://www.kickstarter.com/projects/justusgamesstudio/supreme-courtship-comedy-adventure-justice'>
                        Supreme Courtship Kickstarter Page
                    </ProjectLink>
                </ProjectLinks>

                <ProjectGallery
                    items={dataSupremeCourtship}
                    lightbox={lightbox}
                    setLightbox={setLightbox}
                />
            </Project>
            <Footer className='project-footer' />
        </div>
    );
}

export default SupremeCourtship;
