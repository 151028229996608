import React from 'react';

import supremeCourtshipLogo from '../_img/gallery/supreme-courtship-logo.png';
import supremeCourtshipLogoThumb from '../_img/gallery/thumbs/supreme-courtship-logo.png';
import supremeCourtshipStoryboard from '../_img/gallery/supreme-courtship-storyboard.gif';
import supremeCourtshipStoryboardThumb from '../_img/gallery/thumbs/supreme-courtship-storyboard.gif';
import supremeCourtshipTrailer from '../_img/gallery/supreme-courtship-trailer.mp4';
import supremeCourtshipTrailerThumb from '../_img/gallery/thumbs/supreme-courtship-trailer.gif';
import supremeCourtshipTrailerPoster from '../_img/gallery/posters/supreme-courtship-trailer.png';
import documentary from '../_img/projects/supreme-courtship/documentary.mp4';
import documentaryThumb from '../_img/projects/supreme-courtship/thumbs/documentary-snippet.gif';
import documentaryPoster from '../_img/projects/supreme-courtship/posters/documentary.png';
import cinematic from '../_img/projects/supreme-courtship/cinematic.gif';
import cinematicThumb from '../_img/projects/supreme-courtship/thumbs/cinematic.gif';
import arrivalCG from '../_img/projects/supreme-courtship/arrival-cg.png';
import arrivalCGThumb from '../_img/projects/supreme-courtship/thumbs/arrival-cg.png';
import charAlito from '../_img/projects/supreme-courtship/char-alito.png';
import charAlitoThumb from '../_img/projects/supreme-courtship/thumbs/char-alito.png';
import charBeamer from '../_img/projects/supreme-courtship/char-beamer.png';
import charBeamerThumb from '../_img/projects/supreme-courtship/thumbs/char-beamer.png';
import charBigBucks from '../_img/projects/supreme-courtship/char-bigbucks.png';
import charBigBucksThumb from '../_img/projects/supreme-courtship/thumbs/char-bigbucks.png';
import charBreyer from '../_img/projects/supreme-courtship/char-breyer.png';
import charBreyerThumb from '../_img/projects/supreme-courtship/thumbs/char-breyer.png';
import charBob from '../_img/projects/supreme-courtship/char-bob.png';
import charBobThumb from '../_img/projects/supreme-courtship/thumbs/char-bob.png';
import charGinsburg from '../_img/projects/supreme-courtship/char-ginsburg.png';
import charGinsburgThumb from '../_img/projects/supreme-courtship/thumbs/char-ginsburg.png';
import charJill from '../_img/projects/supreme-courtship/char-jill.png';
import charJillThumb from '../_img/projects/supreme-courtship/thumbs/char-jill.png';
import charKagan from '../_img/projects/supreme-courtship/char-kagan.png';
import charKaganThumb from '../_img/projects/supreme-courtship/thumbs/char-kagan.png';
import charKennedy from '../_img/projects/supreme-courtship/char-kennedy.png';
import charKennedyThumb from '../_img/projects/supreme-courtship/thumbs/char-kennedy.png';
import charPlayerF from '../_img/projects/supreme-courtship/char-player-f.png';
import charPlayerFThumb from '../_img/projects/supreme-courtship/thumbs/char-player-f.png';
import charPlayerM from '../_img/projects/supreme-courtship/char-player-m.png';
import charPlayerMThumb from '../_img/projects/supreme-courtship/thumbs/char-player-m.png';
import charRoberts from '../_img/projects/supreme-courtship/char-roberts.png';
import charRobertsThumb from '../_img/projects/supreme-courtship/thumbs/char-roberts.png';
import charSotomayor from '../_img/projects/supreme-courtship/char-sotomayor.png';
import charSotomayorThumb from '../_img/projects/supreme-courtship/thumbs/char-sotomayor.png';
import charThomas from '../_img/projects/supreme-courtship/char-thomas.png';
import charThomasThumb from '../_img/projects/supreme-courtship/thumbs/char-thomas.png';
import charTreasury from '../_img/projects/supreme-courtship/char-treasury.png';
import charTreasuryThumb from '../_img/projects/supreme-courtship/thumbs/char-treasury.png';

const dataSupremeCourtship = [
    {
        title: 'Game Logo',
        id: 47,
        date: { m: 11, d: 10, y: 2018 },
        description: (
            <p>
                One of the early things I designed for the project was its logo.
                This was such an important piece to help set the tone and
                aesthetic of the game. Since this is a game about the
                government, I wanted the logo design to represent a government
                department seal, much like the actual seal of the Supreme Court.
                But I also wanted to make it clear that this was a fun, flirty
                game. So I took a lot of elements from romantic dating simulator
                games as well. Finally, our game's tagline was added: Romance,
                Comedy, Jurisprudence!
            </p>
        ),
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: supremeCourtshipLogoThumb,
        thumbSize: '1:1',
        img: supremeCourtshipLogo
    },
    {
        title: 'Game Trailer',
        id: 44,
        date: { m: 2, d: 3, y: 2019 },
        description: (
            <p>
                This is the final game trailer I made for the project. There
                were a lot of pieces involved in making this, from collecting
                all the game snippets (all the game UI is also designed by me!),
                to directing the music production, to editing and putting it all
                together! Doesn't this make you want to be a Justice?
            </p>
        ),
        skills: ['art-direction', 'animation', 'design'],
        tools: ['after-effects', 'illustrator', 'photoshop', 'unity'],
        type: 'video',
        thumb: supremeCourtshipTrailerThumb,
        thumbSize: '16:9',
        img: supremeCourtshipTrailer,
        poster: supremeCourtshipTrailerPoster
    },
    {
        title: 'Cinematic Storyboard',
        id: 48,
        date: { m: 8, d: 4, y: 2018 },
        description: (
            <p>
                We wanted to start our game off with a murder mystery cinematic.
                In this scene, Justice Scalia is murdered by an unknown woman
                and her accomplice, setting up the rest of the game where you
                take Scalia's place! We wanted this scene to give off a noir
                detective feel. The final shots in the game ended up a bit
                different, but I have a fondness for this initial storyboard!
            </p>
        ),
        skills: ['animation', 'storyboarding'],
        tools: ['photoshop'],
        type: 'gif',
        thumb: supremeCourtshipStoryboardThumb,
        thumbSize: '4:3',
        img: supremeCourtshipStoryboard
    },
    {
        title: 'Kickstarter Video',
        id: 50,
        date: { m: 2, d: 3, y: 2019 },
        description: (
            <p>
                For our Kickstarter campaign, we wanted to make a video showing
                our small team and touching upon some of the political topics of
                the game. I worked with a videographer to design the sets and
                costumes in this video, and I co-wrote and edited the script.
            </p>
        ),
        skills: ['art-direction', 'animation', 'design'],
        tools: ['after-effects', 'illustrator', 'photoshop'],
        type: 'video',
        thumb: documentaryThumb,
        thumbSize: '16:9',
        img: documentary,
        poster: documentaryPoster
    },
    {
        title: 'Opening Cinematic',
        id: 51,
        date: { m: 11, d: 13, y: 2018 },
        description: (
            <p>
                We wanted to start our game off with a murder mystery cinematic.
                In this scene, Justice Scalia is murdered by an unknown woman
                and her accomplice, setting up the rest of the game where you
                take Scalia's place! We wanted this scene to give off a noir
                detective feel. I drew all the character illustrations in this
                sequence, as well as a handful of the backgrounds.
            </p>
        ),
        skills: ['art-direction', 'animation', 'illustration'],
        tools: ['photoshop', 'unity'],
        type: 'gif',
        thumb: cinematicThumb,
        thumbSize: '16:9',
        img: cinematic
    },
    {
        title: 'Arrival Custom Graphic',
        id: 53,
        date: { m: 1, d: 20, y: 2019 },
        description: (
            <p>
                We wanted a unique image showing all the Justices to be
                displayed in the game when the player first meets these
                characters. This was supposed to be the Justices in their
                natural habitat; right after arguing a case!
            </p>
        ),
        skills: ['illustration', 'background-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: arrivalCGThumb,
        thumbSize: '4:3',
        img: arrivalCG
    },
    {
        title: 'Alito Character Sprites',
        id: 54,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charAlitoThumb,
        thumbSize: '4:3',
        img: charAlito
    },
    {
        title: 'Rep. Beamer Character Sprites',
        id: 55,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charBeamerThumb,
        thumbSize: '4:3',
        img: charBeamer
    },
    {
        title: 'BigBucks Employee Character Sprites',
        id: 56,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charBigBucksThumb,
        thumbSize: '4:3',
        img: charBigBucks
    },
    {
        title: 'Breyer Character Sprites',
        id: 57,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charBreyerThumb,
        thumbSize: '4:3',
        img: charBreyer
    },
    {
        title: 'Newscaster Bob Character Sprites',
        id: 58,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charBobThumb,
        thumbSize: '4:3',
        img: charBob
    },
    {
        title: 'Ginsburg Character Sprites',
        id: 59,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charGinsburgThumb,
        thumbSize: '4:3',
        img: charGinsburg
    },
    {
        title: 'Newscaster Jill Character Sprites',
        id: 60,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charJillThumb,
        thumbSize: '4:3',
        img: charJill
    },
    {
        title: 'Kagan Character Sprites',
        id: 61,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charKaganThumb,
        thumbSize: '4:3',
        img: charKagan
    },
    {
        title: 'Kennedy Character Sprites',
        id: 62,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charKennedyThumb,
        thumbSize: '4:3',
        img: charKennedy
    },
    {
        title: 'Main Character (F) Character Sprites',
        id: 63,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charPlayerFThumb,
        thumbSize: '4:3',
        img: charPlayerF
    },
    {
        title: 'Main Character (M) Character Sprites',
        id: 64,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charPlayerMThumb,
        thumbSize: '4:3',
        img: charPlayerM
    },
    {
        title: 'Roberts Character Sprites',
        id: 65,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charRobertsThumb,
        thumbSize: '4:3',
        img: charRoberts
    },
    {
        title: 'Sotomayor Character Sprites',
        id: 66,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charSotomayorThumb,
        thumbSize: '4:3',
        img: charSotomayor
    },
    {
        title: 'Thomas Character Sprites',
        id: 67,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charThomasThumb,
        thumbSize: '4:3',
        img: charThomas
    },
    {
        title: 'Treasury Employee Character Sprites',
        id: 68,
        date: { m: 12, d: 13, y: 2018 },
        description: <p></p>,
        skills: ['illustration', 'character-design'],
        tools: ['photoshop'],
        type: 'image',
        thumb: charTreasuryThumb,
        thumbSize: '4:3',
        img: charTreasury
    }
];

let randomizedGallery = dataSupremeCourtship.sort(() => Math.random() - 0.5);

export default randomizedGallery;
