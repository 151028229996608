import React from 'react';

import dataGallery from '../_data/data-gallery';
import { galleryFilter, galleryFilterKeys } from '../_data/data-filters';

import Nav from '../components/nav';
import Footer from '../components/footer';
import Lightbox from '../components/gallery/lightbox';
import GalleryContainer from '../components/gallery/gallery';
import { GalleryBtn } from '../components/buttons';
import { FilteredContent, FilterSidebar } from '../components/gallery/filter';
import useDocumentTitle from '../util/use-document-title';

//-----------------------------------------------------------------------

function GalleryPage(props) {
    useDocumentTitle('Gallery');

    return (
        <div className='page'>
            <Nav />
            <FilteredContent
                keys={galleryFilterKeys}
                data={dataGallery}
                itemComponent={GalleryBtn}
                containerComponent={GalleryContainer}
                lightboxComponent={Lightbox}
            />
            <FilterSidebar filter={galleryFilter} keys={galleryFilterKeys} />
            <Footer className='gallery-footer' />
        </div>
    );
}

export default GalleryPage;
