import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SocialBtns } from '../buttons';

import init from '../../_img/homepage-header-init.gif';
import transition from '../../_img/homepage-header-transition.gif';
import final from '../../_img/homepage-header-final.gif';

// -- Header Splash ------------------------------------------------
// I need to first check if this is a first app load (get fed a prop from app -> homepage -> me)
// Then I need to wait till the init image loads, and then display
// Then I need to wait till both the transition and the final image load, then show transition followed by final

function HeaderSplash(props) {
    const { hash } = useLocation();
    const [initLoaded, setInitLoaded] = useState(false);
    const [nextUp, setNextUp] = useState('');
    const [transitionLoaded, setTransitionLoaded] = useState(false);
    const [finalLoaded, setFinalLoaded] = useState(false);
    const [state, setState] = useState(
        props.state || hash ? 'collapsed' : 'expanded'
    );
    const [theme, setTheme] = useState(props.theme ? props.theme : 'gray');
    const scrollDelay = 350;

    useEffect(() => {
        if (state === 'expanded' && !hash) {
            document.body.classList.add('modal-open');
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, scrollDelay);
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [state, hash]);

    useEffect(() => {
        if (state === 'expanded')
            if (initLoaded) {
                let img = document.getElementsByClassName('init');

                if (img.length) img = img[0];

                img.classList.remove('inactive');
                setTimeout(() => {
                    setNextUp('transition');
                }, 1000);
            }
    }, [initLoaded, state, setNextUp]);

    useEffect(() => {
        if (
            state === 'expanded' &&
            transition &&
            finalLoaded &&
            nextUp === 'transition'
        ) {
            let loading = document.getElementsByClassName('loading');
            if (loading.length) loading = loading[0];
            loading.classList.add('inactive');

            let initImg = document.getElementsByClassName('init');
            if (initImg.length) initImg = initImg[0];
            initImg.classList.add('inactive');

            let transImg = document.getElementsByClassName('transition');
            if (transImg.length) transImg = transImg[0];
            transImg.classList.remove('inactive');

            setTimeout(() => {
                setTheme('bright');
            }, 500);

            setTimeout(() => {
                let finalImg = document.getElementsByClassName('final');
                transImg.classList.add('inactive');

                if (finalImg.length) finalImg = finalImg[0];
                finalImg.classList.remove('inactive');

                setState('collapsed');
            }, 1350);

            setTimeout(() => {
                document.body.classList.remove('modal-open');
            }, 2500);
        }
    }, [transitionLoaded, finalLoaded, nextUp, state, setNextUp, setState]);

    return (
        <>
            <header
                id='header-splash'
                className={'header-splash ' + state + ' ' + theme}>
                <div className='left'>
                    <HeaderNameBox />
                </div>
                <div className='right'>
                    <span
                        className={
                            'loading ' +
                            (state === 'collapsed' ? 'inactive' : '')
                        }>
                        Loading...
                    </span>
                    <img
                        className='init inactive'
                        src={init}
                        alt='Sonia Kandah'
                        onLoad={() => {
                            setInitLoaded(true);
                        }}
                    />
                    <img
                        className='transition inactive'
                        src={transition}
                        alt='Sonia Kandah'
                        onLoad={() => {
                            setTransitionLoaded(true);
                        }}
                    />
                    <img
                        className={
                            'final ' + (state === 'collapsed' ? '' : 'inactive')
                        }
                        src={final}
                        alt='Sonia Kandah'
                        onLoad={() => {
                            setFinalLoaded(true);
                        }}
                    />
                </div>
            </header>
            <div className='header-splash-placeholder' />
        </>
    );
}

// -- Name Box -------------------------------------------------------

function HeaderNameBox(props) {
    let prefix = props.prefix || 'Hi, my name is';
    let content = props.content || 'Sonia Kandah';

    return (
        <div className='header-namebox'>
            <div className='heading bold'>
                <h4>{prefix}</h4>
            </div>
            <div className='bubble'>
                <h1>{content}</h1>
            </div>
            <div className='namebox-footer'>
                <SocialBtns />
            </div>
        </div>
    );
}

export default HeaderSplash;
