import React from 'react';

import hcBack01 from '../_img/projects/playing-cards/hylian-court/back-01.png';
import hcBack01Thumb from '../_img/projects/playing-cards/hylian-court/thumbs/back-01.png';
import hcBack02 from '../_img/projects/playing-cards/hylian-court/back-02.png';
import hcBack02Thumb from '../_img/projects/playing-cards/hylian-court/thumbs/back-02.png';

import hcSpadeJ from '../_img/projects/playing-cards/hylian-court/spade-j.png';
import hcSpadeJThumb from '../_img/projects/playing-cards/hylian-court/thumbs/spade-j.png';
import hcSpadeQ from '../_img/projects/playing-cards/hylian-court/spade-q.png';
import hcSpadeQThumb from '../_img/projects/playing-cards/hylian-court/thumbs/spade-q.png';
import hcSpadeQAlt from '../_img/projects/playing-cards/hylian-court/spade-q-alt.png';
import hcSpadeQAltThumb from '../_img/projects/playing-cards/hylian-court/thumbs/spade-q-alt.png';
import hcSpadeK from '../_img/projects/playing-cards/hylian-court/spade-k.png';
import hcSpadeKThumb from '../_img/projects/playing-cards/hylian-court/thumbs/spade-k.png';
import hcSpadeA from '../_img/projects/playing-cards/hylian-court/spade-a.png';
import hcSpadeAThumb from '../_img/projects/playing-cards/hylian-court/thumbs/spade-a.png';

import hcDiamondJ from '../_img/projects/playing-cards/hylian-court/diamond-j.png';
import hcDiamondJThumb from '../_img/projects/playing-cards/hylian-court/thumbs/diamond-j.png';
import hcDiamondQ from '../_img/projects/playing-cards/hylian-court/diamond-q.png';
import hcDiamondQThumb from '../_img/projects/playing-cards/hylian-court/thumbs/diamond-q.png';
import hcDiamondK from '../_img/projects/playing-cards/hylian-court/diamond-k.png';
import hcDiamondKThumb from '../_img/projects/playing-cards/hylian-court/thumbs/diamond-k.png';
import hcDiamondA from '../_img/projects/playing-cards/hylian-court/diamond-a.png';
import hcDiamondAThumb from '../_img/projects/playing-cards/hylian-court/thumbs/diamond-a.png';

import hcClubJ from '../_img/projects/playing-cards/hylian-court/club-j.png';
import hcClubJThumb from '../_img/projects/playing-cards/hylian-court/thumbs/club-j.png';
import hcClubQ from '../_img/projects/playing-cards/hylian-court/club-q.png';
import hcClubQThumb from '../_img/projects/playing-cards/hylian-court/thumbs/club-q.png';
import hcClubQAlt from '../_img/projects/playing-cards/hylian-court/club-q-alt.png';
import hcClubQAltThumb from '../_img/projects/playing-cards/hylian-court/thumbs/club-q-alt.png';
import hcClubK from '../_img/projects/playing-cards/hylian-court/club-k.png';
import hcClubKThumb from '../_img/projects/playing-cards/hylian-court/thumbs/club-k.png';
import hcClubA from '../_img/projects/playing-cards/hylian-court/club-a.png';
import hcClubAThumb from '../_img/projects/playing-cards/hylian-court/thumbs/club-a.png';

import hcHeartJ from '../_img/projects/playing-cards/hylian-court/heart-j.png';
import hcHeartJThumb from '../_img/projects/playing-cards/hylian-court/thumbs/heart-j.png';
import hcHeartQ from '../_img/projects/playing-cards/hylian-court/heart-q.png';
import hcHeartQThumb from '../_img/projects/playing-cards/hylian-court/thumbs/heart-q.png';
import hcHeartK from '../_img/projects/playing-cards/hylian-court/heart-k.png';
import hcHeartKThumb from '../_img/projects/playing-cards/hylian-court/thumbs/heart-k.png';
import hcHeartKAlt from '../_img/projects/playing-cards/hylian-court/heart-k-alt.png';
import hcHeartKAltThumb from '../_img/projects/playing-cards/hylian-court/thumbs/heart-k-alt.png';
import hcHeartA from '../_img/projects/playing-cards/hylian-court/heart-a.png';
import hcHeartAThumb from '../_img/projects/playing-cards/hylian-court/thumbs/heart-a.png';

import hcJokerB from '../_img/projects/playing-cards/hylian-court/joker-b.png';
import hcJokerBThumb from '../_img/projects/playing-cards/hylian-court/thumbs/joker-b.png';
import hcJokerR from '../_img/projects/playing-cards/hylian-court/joker-r.png';
import hcJokerRThumb from '../_img/projects/playing-cards/hylian-court/thumbs/joker-r.png';
import hcJokerRAlt from '../_img/projects/playing-cards/hylian-court/joker-r-alt.png';
import hcJokerRAltThumb from '../_img/projects/playing-cards/hylian-court/thumbs/joker-r-alt.png';

import mpBack from '../_img/projects/playing-cards/moon-prism/back.png';
import mpBackThumb from '../_img/projects/playing-cards/moon-prism/thumbs/back.png';

import mpSpadeJ01 from '../_img/projects/playing-cards/moon-prism/spade-j-01.png';
import mpSpadeJ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-j-01.png';
import mpSpadeJ02 from '../_img/projects/playing-cards/moon-prism/spade-j-02.png';
import mpSpadeJ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-j-02.png';
import mpSpadeJ03 from '../_img/projects/playing-cards/moon-prism/spade-j-03.png';
import mpSpadeJ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-j-03.png';
import mpSpadeQ01 from '../_img/projects/playing-cards/moon-prism/spade-q-01.png';
import mpSpadeQ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-q-01.png';
import mpSpadeQ02 from '../_img/projects/playing-cards/moon-prism/spade-q-02.png';
import mpSpadeQ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-q-02.png';
import mpSpadeQ03 from '../_img/projects/playing-cards/moon-prism/spade-q-03.png';
import mpSpadeQ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-q-03.png';
import mpSpadeK01 from '../_img/projects/playing-cards/moon-prism/spade-k-01.png';
import mpSpadeK01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-k-01.png';
import mpSpadeK02 from '../_img/projects/playing-cards/moon-prism/spade-k-02.png';
import mpSpadeK02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-k-02.png';
import mpSpadeK03 from '../_img/projects/playing-cards/moon-prism/spade-k-03.png';
import mpSpadeK03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-k-03.png';
import mpSpadeA from '../_img/projects/playing-cards/moon-prism/spade-a.png';
import mpSpadeAThumb from '../_img/projects/playing-cards/moon-prism/thumbs/spade-a.png';

import mpDiamondJ01 from '../_img/projects/playing-cards/moon-prism/diamond-j-01.png';
import mpDiamondJ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-j-01.png';
import mpDiamondJ02 from '../_img/projects/playing-cards/moon-prism/diamond-j-02.png';
import mpDiamondJ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-j-02.png';
import mpDiamondJ03 from '../_img/projects/playing-cards/moon-prism/diamond-j-03.png';
import mpDiamondJ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-j-03.png';
import mpDiamondQ01 from '../_img/projects/playing-cards/moon-prism/diamond-q-01.png';
import mpDiamondQ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-q-01.png';
import mpDiamondQ02 from '../_img/projects/playing-cards/moon-prism/diamond-q-02.png';
import mpDiamondQ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-q-02.png';
import mpDiamondQ03 from '../_img/projects/playing-cards/moon-prism/diamond-q-03.png';
import mpDiamondQ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-q-03.png';
import mpDiamondK01 from '../_img/projects/playing-cards/moon-prism/diamond-k-01.png';
import mpDiamondK01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-k-01.png';
import mpDiamondK02 from '../_img/projects/playing-cards/moon-prism/diamond-k-02.png';
import mpDiamondK02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-k-02.png';
import mpDiamondK03 from '../_img/projects/playing-cards/moon-prism/diamond-k-03.png';
import mpDiamondK03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-k-03.png';
import mpDiamondA from '../_img/projects/playing-cards/moon-prism/diamond-a.png';
import mpDiamondAThumb from '../_img/projects/playing-cards/moon-prism/thumbs/diamond-a.png';

import mpClubJ01 from '../_img/projects/playing-cards/moon-prism/club-j-01.png';
import mpClubJ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-j-01.png';
import mpClubJ02 from '../_img/projects/playing-cards/moon-prism/club-j-02.png';
import mpClubJ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-j-02.png';
import mpClubJ03 from '../_img/projects/playing-cards/moon-prism/club-j-03.png';
import mpClubJ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-j-03.png';
import mpClubQ01 from '../_img/projects/playing-cards/moon-prism/club-q-01.png';
import mpClubQ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-q-01.png';
import mpClubQ02 from '../_img/projects/playing-cards/moon-prism/club-q-02.png';
import mpClubQ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-q-02.png';
import mpClubQ03 from '../_img/projects/playing-cards/moon-prism/club-q-03.png';
import mpClubQ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-q-03.png';
import mpClubK01 from '../_img/projects/playing-cards/moon-prism/club-k-01.png';
import mpClubK01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-k-01.png';
import mpClubK02 from '../_img/projects/playing-cards/moon-prism/club-k-02.png';
import mpClubK02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-k-02.png';
import mpClubK03 from '../_img/projects/playing-cards/moon-prism/club-k-03.png';
import mpClubK03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-k-03.png';
import mpClubA from '../_img/projects/playing-cards/moon-prism/club-a.png';
import mpClubAThumb from '../_img/projects/playing-cards/moon-prism/thumbs/club-a.png';

import mpHeartJ01 from '../_img/projects/playing-cards/moon-prism/heart-j-01.png';
import mpHeartJ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-j-01.png';
import mpHeartJ02 from '../_img/projects/playing-cards/moon-prism/heart-j-02.png';
import mpHeartJ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-j-02.png';
import mpHeartJ03 from '../_img/projects/playing-cards/moon-prism/heart-j-03.png';
import mpHeartJ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-j-03.png';
import mpHeartQ01 from '../_img/projects/playing-cards/moon-prism/heart-q-01.png';
import mpHeartQ01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-q-01.png';
import mpHeartQ02 from '../_img/projects/playing-cards/moon-prism/heart-q-02.png';
import mpHeartQ02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-q-02.png';
import mpHeartQ03 from '../_img/projects/playing-cards/moon-prism/heart-q-03.png';
import mpHeartQ03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-q-03.png';
import mpHeartK01 from '../_img/projects/playing-cards/moon-prism/heart-k-01.png';
import mpHeartK01Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-k-01.png';
import mpHeartK02 from '../_img/projects/playing-cards/moon-prism/heart-k-02.png';
import mpHeartK02Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-k-02.png';
import mpHeartK03 from '../_img/projects/playing-cards/moon-prism/heart-k-03.png';
import mpHeartK03Thumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-k-03.png';
import mpHeartA from '../_img/projects/playing-cards/moon-prism/heart-a.png';
import mpHeartAThumb from '../_img/projects/playing-cards/moon-prism/thumbs/heart-a.png';

import mpJokerB from '../_img/projects/playing-cards/moon-prism/joker-b.png';
import mpJokerBThumb from '../_img/projects/playing-cards/moon-prism/thumbs/joker-b.png';
import mpJokerBAlt from '../_img/projects/playing-cards/moon-prism/joker-b-alt.png';
import mpJokerBAltThumb from '../_img/projects/playing-cards/moon-prism/thumbs/joker-b-alt.png';
import mpJokerR from '../_img/projects/playing-cards/moon-prism/joker-r.png';
import mpJokerRThumb from '../_img/projects/playing-cards/moon-prism/thumbs/joker-r.png';
import mpJokerRAlt from '../_img/projects/playing-cards/moon-prism/joker-r-alt.png';
import mpJokerRAltThumb from '../_img/projects/playing-cards/moon-prism/thumbs/joker-r-alt.png';

import reBack from '../_img/projects/playing-cards/rebel-empire/back.png';
import reBackThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/back.png';

import reSpadeJ from '../_img/projects/playing-cards/rebel-empire/spade-j.png';
import reSpadeJThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/spade-j.png';
import reSpadeQ from '../_img/projects/playing-cards/rebel-empire/spade-q.png';
import reSpadeQThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/spade-q.png';
import reSpadeK from '../_img/projects/playing-cards/rebel-empire/spade-k.png';
import reSpadeKThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/spade-k.png';

import reDiamondJ from '../_img/projects/playing-cards/rebel-empire/diamond-j.png';
import reDiamondJThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/diamond-j.png';
import reDiamondQ from '../_img/projects/playing-cards/rebel-empire/diamond-q.png';
import reDiamondQThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/diamond-q.png';
import reDiamondK from '../_img/projects/playing-cards/rebel-empire/diamond-k.png';
import reDiamondKThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/diamond-k.png';

import reClubJ from '../_img/projects/playing-cards/rebel-empire/club-j.png';
import reClubJThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/club-j.png';
import reClubQ from '../_img/projects/playing-cards/rebel-empire/club-q.png';
import reClubQThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/club-q.png';
import reClubK from '../_img/projects/playing-cards/rebel-empire/club-k.png';
import reClubKThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/club-k.png';

import reHeartJ from '../_img/projects/playing-cards/rebel-empire/heart-j.png';
import reHeartJThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/heart-j.png';
import reHeartQ from '../_img/projects/playing-cards/rebel-empire/heart-q.png';
import reHeartQThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/heart-q.png';
import reHeartK from '../_img/projects/playing-cards/rebel-empire/heart-k.png';
import reHeartKThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/heart-k.png';

import reJokerB from '../_img/projects/playing-cards/rebel-empire/joker-b.png';
import reJokerBThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/joker-b.png';
import reJokerR from '../_img/projects/playing-cards/rebel-empire/joker-r.png';
import reJokerRThumb from '../_img/projects/playing-cards/rebel-empire/thumbs/joker-r.png';

const dataHylianCourt = [
    {
        title: 'Card Back Design',
        id: 1,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcBack01Thumb,
        img: hcBack01
    },
    {
        title: 'Alternate Card Back Design',
        id: 2,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcBack02Thumb,
        img: hcBack02
    },
    {
        title: 'Jack of Spades - Link',
        id: 3,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcSpadeJThumb,
        img: hcSpadeJ
    },
    {
        title: 'Queen of Spades - Zelda',
        id: 4,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcSpadeQThumb,
        img: hcSpadeQ
    },
    {
        title: 'Alternate Queen of Spades - Sheik',
        id: 4,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcSpadeQAltThumb,
        img: hcSpadeQAlt
    },
    {
        title: 'King of Spades - Ganondorf',
        id: 5,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcSpadeKThumb,
        img: hcSpadeK
    },
    {
        title: 'Ace of Spades - Triforce',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcSpadeAThumb,
        img: hcSpadeA
    },
    {
        title: 'Jack of Diamonds - Saria',
        id: 3,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcDiamondJThumb,
        img: hcDiamondJ
    },
    {
        title: 'Queen of Diamonds - Ruto',
        id: 4,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcDiamondQThumb,
        img: hcDiamondQ
    },
    {
        title: 'King of Diamonds - Darunia',
        id: 5,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcDiamondKThumb,
        img: hcDiamondK
    },
    {
        title: 'Ace of Diamonds - Rupees',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcDiamondAThumb,
        img: hcDiamondA
    },
    {
        title: 'Jack of Clubs - Impa',
        id: 3,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcClubJThumb,
        img: hcClubJ
    },
    {
        title: 'Queen of Clubs - Midna',
        id: 4,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcClubQThumb,
        img: hcClubQ
    },
    {
        title: 'Alternate Queen of Clubs - Midna',
        id: 4,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcClubQAltThumb,
        img: hcClubQAlt
    },
    {
        title: 'King of Clubs - Zant',
        id: 5,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcClubKThumb,
        img: hcClubK
    },
    {
        title: 'Ace of Clubs - Arrows',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcClubAThumb,
        img: hcClubA
    },
    {
        title: 'Jack of Hearts - Nabooru',
        id: 3,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcHeartJThumb,
        img: hcHeartJ
    },
    {
        title: 'Queen of Hearts - Great Fairy',
        id: 4,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcHeartQThumb,
        img: hcHeartQ
    },
    {
        title: 'King of Hearts - Hyrule',
        id: 5,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcHeartKThumb,
        img: hcHeartK
    },
    {
        title: 'Alternate King of Hearts - Hero Spirit',
        id: 5,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcHeartKAltThumb,
        img: hcHeartKAlt
    },
    {
        title: 'Ace of Hearts - Heart Container',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcHeartAThumb,
        img: hcHeartA
    },
    {
        title: 'Black Joker - Skull Kid',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcJokerBThumb,
        img: hcJokerB
    },
    {
        title: 'Red Joker - Tingle',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcJokerRThumb,
        img: hcJokerR
    },
    {
        title: 'Alternate Red Joker - Happy Mask Salesman',
        id: 6,
        date: { m: 1, d: 8, y: 2013 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hcJokerRAltThumb,
        img: hcJokerRAlt
    }
];

const dataMoonPrism = [
    {
        title: 'Card Back Design',
        id: 1,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpBackThumb,
        img: mpBack
    },
    {
        title: 'Jack of Hearts - Sailor Venus',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartJ01Thumb,
        img: mpHeartJ01
    },
    {
        title: 'Jack of Hearts - Princess Venus',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartJ02Thumb,
        img: mpHeartJ02
    },
    {
        title: 'Jack of Hearts - Sailor V',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartJ03Thumb,
        img: mpHeartJ03
    },
    {
        title: 'Queen of Hearts - Sailor Moon',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartQ01Thumb,
        img: mpHeartQ01
    },
    {
        title: 'Queen of Hearts - Princess Serenity',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartQ02Thumb,
        img: mpHeartQ02
    },
    {
        title: 'Queen of Hearts - Sailor Cosmos',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartQ03Thumb,
        img: mpHeartQ03
    },
    {
        title: 'King of Hearts - Tuxedo Mask',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartK01Thumb,
        img: mpHeartK01
    },
    {
        title: 'King of Hearts - Prince Endymion',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartK02Thumb,
        img: mpHeartK02
    },
    {
        title: 'King of Hearts - Seiya Kou',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartK03Thumb,
        img: mpHeartK03
    },
    {
        title: 'Ace of Hearts - Heart Wand',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpHeartAThumb,
        img: mpHeartA
    },
    {
        title: 'Jack of Diamonds - Sailor Mercury',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondJ01Thumb,
        img: mpDiamondJ01
    },
    {
        title: 'Jack of Diamonds - Princess Mercury',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondJ02Thumb,
        img: mpDiamondJ02
    },
    {
        title: 'Jack of Diamonds - An and Ail',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondJ03Thumb,
        img: mpDiamondJ03
    },
    {
        title: 'Queen of Diamonds - Sailor Mars',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondQ01Thumb,
        img: mpDiamondQ01
    },
    {
        title: 'Queen of Diamonds - Princess Mars',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondQ02Thumb,
        img: mpDiamondQ02
    },
    {
        title: 'Queen of Diamonds - Princess Kakyuu',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondQ03Thumb,
        img: mpDiamondQ03
    },
    {
        title: 'King of Diamonds - Sailor Jupiter',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondK01Thumb,
        img: mpDiamondK01
    },
    {
        title: 'King of Diamonds - Princess Jupiter',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondK02Thumb,
        img: mpDiamondK02
    },
    {
        title: 'King of Diamonds - Prince Demande',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondK03Thumb,
        img: mpDiamondK03
    },
    {
        title: 'Ace of Diamonds - Silver Crystal',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpDiamondAThumb,
        img: mpDiamondA
    },
    {
        title: 'Jack of Clubs - Sailor Saturn',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubJ01Thumb,
        img: mpClubJ01
    },
    {
        title: 'Jack of Clubs - Princess Saturn',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubJ02Thumb,
        img: mpClubJ02
    },
    {
        title: 'Jack of Clubs - Mistress 9',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubJ03Thumb,
        img: mpClubJ03
    },
    {
        title: 'Queen of Clubs - Sailor Neptune',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubQ01Thumb,
        img: mpClubQ01
    },
    {
        title: 'Queen of Clubs - Princess Neptune',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubQ02Thumb,
        img: mpClubQ02
    },
    {
        title: 'Queen of Clubs - Queen Beryl',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubQ03Thumb,
        img: mpClubQ03
    },
    {
        title: 'King of Clubs - Sailor Uranus',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubK01Thumb,
        img: mpClubK01
    },
    {
        title: 'King of Clubs - Princess Uranus',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubK02Thumb,
        img: mpClubK02
    },
    {
        title: 'King of Clubs - Professor Tomoe',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubK03Thumb,
        img: mpClubK03
    },
    {
        title: 'Ace of Clubs - Star Seed',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpClubAThumb,
        img: mpClubA
    },
    {
        title: 'Jack of Spades - Sailor Pluto',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeJ01Thumb,
        img: mpSpadeJ01
    },
    {
        title: 'Jack of Spades - Princess Pluto',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeJ02Thumb,
        img: mpSpadeJ02
    },
    {
        title: 'Jack of Spades - Queen Nehelenia',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeJ03Thumb,
        img: mpSpadeJ03
    },
    {
        title: 'Queen of Spades - Sailor Chibi Moon',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeQ01Thumb,
        img: mpSpadeQ01
    },
    {
        title: 'Queen of Spades - Princess Chibiusa',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeQ02Thumb,
        img: mpSpadeQ02
    },
    {
        title: 'Queen of Spades - Black Lady',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeQ03Thumb,
        img: mpSpadeQ03
    },
    {
        title: 'King of Spades - Prince Helios',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeK01Thumb,
        img: mpSpadeK01
    },
    {
        title: 'King of Spades - Princess Galaxia',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeK02Thumb,
        img: mpSpadeK02
    },
    {
        title: 'King of Spades - Sailor Galaxia',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeK03Thumb,
        img: mpSpadeK03
    },
    {
        title: 'Ace of Spades - Moon',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpSpadeAThumb,
        img: mpSpadeA
    },
    {
        title: 'Red Joker - Luna, Artemis and Diana',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpJokerRThumb,
        img: mpJokerR
    },
    {
        title: 'Red Joker - Luna, Artemis and Diana (Human)',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpJokerRAltThumb,
        img: mpJokerRAlt
    },
    {
        title: 'Black Joker - Sailor Chibi Chibi',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpJokerBThumb,
        img: mpJokerB
    },
    {
        title: 'Black Joker - Sailor Parallel Moon',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: mpJokerBAltThumb,
        img: mpJokerBAlt
    }
];

const dataRebelEmpire = [
    {
        title: 'Card Back Design',
        id: 1,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reBackThumb,
        img: reBack
    },
    {
        title: 'Jack of Hearts - Luke Skywalker',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reHeartJThumb,
        img: reHeartJ
    },
    {
        title: 'Queen of Hearts - Leia Skywalker',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reHeartQThumb,
        img: reHeartQ
    },
    {
        title: 'King of Hearts - Han Solo',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reHeartKThumb,
        img: reHeartK
    },
    {
        title: 'Jack of Clubs - Boba Fett',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reClubJThumb,
        img: reClubJ
    },
    {
        title: 'Queen of Clubs - Captain Phasma',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reClubQThumb,
        img: reClubQ
    },
    {
        title: 'King of Clubs - Emperor Palpatine',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reClubKThumb,
        img: reClubK
    },
    {
        title: 'Jack of Diamonds - Obi Wan Kenobi',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reDiamondJThumb,
        img: reDiamondJ
    },
    {
        title: 'Queen of Diamonds - Asoka Tano',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reDiamondQThumb,
        img: reDiamondQ
    },
    {
        title: 'King of Diamonds - Yoda',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reDiamondKThumb,
        img: reDiamondK
    },
    {
        title: 'Jack of Spades - Kylo Ren',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reSpadeJThumb,
        img: reSpadeJ
    },
    {
        title: 'Queen of Spades - Padme Amidala',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reSpadeQThumb,
        img: reSpadeQ
    },
    {
        title: 'King of Spades - Darth Vader',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reSpadeKThumb,
        img: reSpadeK
    },
    {
        title: 'Red Joker - Chewbacca',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reJokerBThumb,
        img: reJokerB
    },
    {
        title: 'Black Joker - R2D2 and C3PO',
        id: 4,
        date: { m: 3, d: 27, y: 2015 },
        description: <p></p>,
        skills: ['design', 'character-design', 'illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: reJokerRThumb,
        img: reJokerR
    }
];

export { dataHylianCourt, dataMoonPrism, dataRebelEmpire };
