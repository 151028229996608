import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumbs(props) {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <nav className={'breadcrumbs ' + props.className}>
            <Link to='/'>
                <span className='text'>home</span>
                <i className='endcap fa-solid fa-caret-right' />
            </Link>

            {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                return (
                    <Link to={routeTo} key={index}>
                        <i className='cutout fa-solid fa-caret-right' />
                        <span className='text'>{name.replace(/-/g, ' ')}</span>
                        <i className='endcap fa-solid fa-caret-right' />
                    </Link>
                );
            })}
        </nav>
    );
}

export default Breadcrumbs;
