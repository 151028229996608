import React from 'react';
import { SocialBtns } from './buttons';
import { NavText } from './nav';

function Footer(props) {
    return (
        <footer className={'footer ' + props.className}>
            <div className='container'>
                <p>
                    Copyright <i className='fa-solid fa-copyright' />{' '}
                    {new Date().getFullYear()} Sonia Kandah
                </p>
                <NavText className='muted' />
                <SocialBtns className='muted' />
            </div>
        </footer>
    );
}

export default Footer;
