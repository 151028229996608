import React from 'react';

import aotmBardPoster from '../_img/gallery/aotm-bard-poster.png';
import aotmBardPosterThumb from '../_img/gallery/thumbs/aotm-bard-poster.png';
import aotmCharacterIntros from '../_img/gallery/aotm-character-intros.mp4';
import aotmCharacterIntrosThumb from '../_img/gallery/thumbs/aotm-character-intros.gif';
import aotmCharacterIntrosPoster from '../_img/gallery/posters/aotm-character-intros.png';
import aotmIfiExpressions from '../_img/gallery/aotm-ifi-expressions.png';
import aotmIfiExpressionsThumb from '../_img/gallery/thumbs/aotm-ifi-expressions.png';
import aotmIfiModel from '../_img/gallery/aotm-ifi-model.png';
import aotmIfiModelThumb from '../_img/gallery/thumbs/aotm-ifi-model.png';
import aotmIfiTurnaround2D from '../_img/gallery/aotm-ifi-turnaround-2D.gif';
import aotmIfiTurnaround2DThumb from '../_img/gallery/thumbs/aotm-ifi-turnaround-2D.gif';
import aotmIfiTurnaround3D from '../_img/gallery/aotm-ifi-turnaround-3D.gif';
import aotmIfiTurnaround3DThumb from '../_img/gallery/thumbs/aotm-ifi-turnaround-3D.gif';
import aotmLahaire from '../_img/gallery/aotm-lahaire.png';
import aotmLahaireThumb from '../_img/gallery/thumbs/aotm-lahaire.png';
import aotmOpeningSong from '../_img/gallery/aotm-opening-song.mp4';
import aotmOpeningSongThumb from '../_img/gallery/thumbs/aotm-opening-song.gif';
import aotmOpeningSongPoster from '../_img/gallery/posters/aotm-opening-song.png';
import aotmRozirOutfits from '../_img/gallery/aotm-rozir-outfits.png';
import aotmRozirOutfitsThumb from '../_img/gallery/thumbs/aotm-rozir-outfits.png';
import aotmStoryboardPilot from '../_img/gallery/aotm-storyboard-pilot.mp4';
import aotmStoryboardPilotThumb from '../_img/gallery/thumbs/aotm-storyboard-pilot.gif';
import aotmStoryboardPilotPoster from '../_img/gallery/posters/aotm-storyboard-pilot.png';
import aotmStoryboardPilot02 from '../_img/gallery/aotm-storyboard-pilot-02.mp4';
import aotmStoryboardPilot02Thumb from '../_img/gallery/thumbs/aotm-storyboard-pilot-02.gif';
import aotmStoryboardPilot02Poster from '../_img/gallery/posters/aotm-storyboard-pilot-02.png';
import aotmSyadOutfits from '../_img/gallery/aotm-syad-outfits.png';
import aotmSyadOutfitsThumb from '../_img/gallery/thumbs/aotm-syad-outfits.png';
import aotmTitania from '../_img/gallery/aotm-titania.png';
import aotmTitaniaThumb from '../_img/gallery/thumbs/aotm-titania.png';
import aotmVillainAnimation from '../_img/gallery/aotm-villain-animation.mp4';
import aotmVillainAnimationThumb from '../_img/gallery/thumbs/aotm-villain-animation.gif';
import aotmVillainAnimationPoster from '../_img/gallery/posters/aotm-villain-animation.png';
import mageCardAir from '../_img/gallery/mage-card-air.png';
import mageCardAirThumb from '../_img/gallery/thumbs/mage-card-air.png';
import mageCardCold from '../_img/gallery/mage-card-cold.png';
import mageCardColdThumb from '../_img/gallery/thumbs/mage-card-cold.png';
import mageCardEarth from '../_img/gallery/mage-card-earth.gif';
import mageCardEarthThumb from '../_img/gallery/thumbs/mage-card-earth.gif';
import mageCardFire from '../_img/gallery/mage-card-fire.png';
import mageCardFireThumb from '../_img/gallery/thumbs/mage-card-fire.png';
import mageCardFlora from '../_img/gallery/mage-card-flora.gif';
import mageCardFloraThumb from '../_img/gallery/thumbs/mage-card-flora.gif';
import mageCardWater from '../_img/gallery/mage-card-water.png';
import mageCardWaterThumb from '../_img/gallery/thumbs/mage-card-water.png';
import aotmBeyarsaCharacter from '../_img/gallery/aotm-beyarsa-character.png';
import aotmBeyarsaCharacterThumb from '../_img/gallery/thumbs/aotm-beyarsa-character.png';
import aotmRozirCharacter from '../_img/gallery/aotm-rozir-character.png';
import aotmRozirCharacterThumb from '../_img/gallery/thumbs/aotm-rozir-character.png';
import beyarsaStats from '../_img/projects/age-of-the-mage/Beyarsa-Stats.png';
import beyarsaStatsThumb from '../_img/projects/age-of-the-mage/thumbs/Beyarsa-Stats.png';
import fauna from '../_img/projects/age-of-the-mage/fauna.png';
import faunaThumb from '../_img/projects/age-of-the-mage/thumbs/fauna.png';
import ifiFamilyColorStudy from '../_img/projects/age-of-the-mage/Ifi-Family-Color-Study.png';
import ifiFamilyColorStudyThumb from '../_img/projects/age-of-the-mage/thumbs/Ifi-Family-Color-Study.png';
import ifiStats from '../_img/projects/age-of-the-mage/Ifi-Stats.png';
import ifiStatsThumb from '../_img/projects/age-of-the-mage/thumbs/Ifi-Stats.png';
import ifiOutfits01 from '../_img/projects/age-of-the-mage/ifi-outfits-01.png';
import ifiOutfits01Thumb from '../_img/projects/age-of-the-mage/thumbs/ifi-outfits-01.png';
import ifiOutfits02 from '../_img/projects/age-of-the-mage/ifi-outfits-02.png';
import ifiOutfits02Thumb from '../_img/projects/age-of-the-mage/thumbs/ifi-outfits-02.png';
import ifiOutfits03 from '../_img/projects/age-of-the-mage/ifi-outfits-03.png';
import ifiOutfits03Thumb from '../_img/projects/age-of-the-mage/thumbs/ifi-outfits-03.png';
import light from '../_img/projects/age-of-the-mage/light.png';
import lightThumb from '../_img/projects/age-of-the-mage/thumbs/light.png';
import lo from '../_img/projects/age-of-the-mage/Lo.png';
import loThumb from '../_img/projects/age-of-the-mage/thumbs/Lo.png';
import rozir from '../_img/projects/age-of-the-mage/Rozir-Ohango-Yuda.png';
import rozirThumb from '../_img/projects/age-of-the-mage/thumbs/Rozir-Ohango-Yuda.png';
import rozirStats from '../_img/projects/age-of-the-mage/Rozir-Stats.png';
import rozirStatsThumb from '../_img/projects/age-of-the-mage/thumbs/Rozir-Stats.png';
import shadow from '../_img/projects/age-of-the-mage/shadow.png';
import shadowThumb from '../_img/projects/age-of-the-mage/thumbs/shadow.png';
import syad from '../_img/projects/age-of-the-mage/Syad-Pekenov.png';
import syadThumb from '../_img/projects/age-of-the-mage/thumbs/Syad-Pekenov.png';
import syadStats from '../_img/projects/age-of-the-mage/Syad-Stats.png';
import syadStatsThumb from '../_img/projects/age-of-the-mage/thumbs/Syad-Stats.png';
import cheoStats from '../_img/projects/age-of-the-mage/Cheo-Stats.png';
import cheoStatsThumb from '../_img/projects/age-of-the-mage/thumbs/Cheo-Stats.png';
import gecheOptions from '../_img/projects/age-of-the-mage/geche-options.png';
import gecheOptionsThumb from '../_img/projects/age-of-the-mage/thumbs/geche-options.png';

import pilot01 from '../_img/projects/age-of-the-mage/aotm-pilot-script-01.pdf';
import pilot01Thumb from '../_img/projects/age-of-the-mage/thumbs/aotm-pilot-script-01.png';
import pilot02 from '../_img/projects/age-of-the-mage/aotm-pilot-script-02.pdf';
import pilot02Thumb from '../_img/projects/age-of-the-mage/thumbs/aotm-pilot-script-02.png';
import pilot03 from '../_img/projects/age-of-the-mage/aotm-pilot-script-03.pdf';
import pilot03Thumb from '../_img/projects/age-of-the-mage/thumbs/aotm-pilot-script-03.png';

const dataAotM = [
    {
        title: 'Beyarsa Character Expressions',
        id: 0,
        date: { m: 3, d: 30, y: 2023 },
        description: (
            <p>
                This is a character reference and expression sheet I drew for
                Beyarsa, a main character in this project. I wanted to show off
                her weapons and personality; she's a hardy no-nonsense leader
                who wields a handaxe and arcane shield!
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmBeyarsaCharacterThumb,
        thumbSize: '1:1',
        img: aotmBeyarsaCharacter
    },
    {
        title: 'Rozir Character Expressions',
        id: 0,
        date: { m: 3, d: 30, y: 2023 },
        description: (
            <p>
                This is a character reference and expression sheet I drew for
                Rozir, a main character in this project. I wanted to show off
                his sickle-shaped sword and his firey personality!
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmRozirCharacterThumb,
        thumbSize: '1:1',
        img: aotmRozirCharacter
    },
    {
        title: 'Fantasy Bards Poster',
        id: 0,
        date: { m: 6, d: 28, y: 2022 },
        description: (
            <p>
                Inspired by a{' '}
                <a
                    href='https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/cordoba-feria-de-otono-unknown.jpg'
                    target='_blank'
                    rel='noreferrer'>
                    vintage Spanish poster
                </a>
                , this is a fantasy advertisement for a famous bard duo. This
                poster is hanging from my main character's room; they're Ifi's
                favorite performers!
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate', 'photoshop'],
        type: 'image',
        thumb: aotmBardPosterThumb,
        thumbSize: '3:4',
        img: aotmBardPoster
    },
    {
        title: 'Character Intro Animation',
        id: 1,
        date: { m: 11, d: 29, y: 2022 },
        description: (
            <p>
                This short animation is part of an anime-inspired opening
                sequence I storyboarded. I wanted to quickly show a glimpse of
                each character's personality, and tease their role in the story.
                This animation is only in the sketch stage since the project is
                still in development, but check back later for updates!
            </p>
        ),
        skills: ['character-design', 'animation', 'art-direction'],
        tools: ['procreate', 'storyboard-pro'],
        type: 'video',
        wip: true,
        thumb: aotmCharacterIntrosThumb,
        poster: aotmCharacterIntrosPoster,
        thumbSize: '16:9',
        img: aotmCharacterIntros
    },
    {
        title: 'Ifi Expressions Sketches',
        id: 2,
        date: { m: 11, d: 20, y: 2020 },
        description: (
            <p>
                A quick practice of some expressions and outfits of Ifi, the
                main character in this project.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmIfiExpressionsThumb,
        thumbSize: '3:4',
        img: aotmIfiExpressions
    },
    {
        title: 'Ifi Character Art',
        id: 3,
        date: { m: 11, d: 8, y: 2020 },
        description: (
            <p>
                This is the first character art I drew for Ifi, the main
                character in my project. I wanted a fun, adventurous design, and
                was inspired by Steven Universe and Sailor Moon.
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmIfiModelThumb,
        thumbSize: '4:3',
        img: aotmIfiModel
    },
    {
        title: 'Ifi Character Turnaround (2D)',
        id: 4,
        date: { m: 1, d: 11, y: 2022 },
        description: (
            <p>
                As an early venture into the world of 2D animation, I wanted to
                try making a fully rendered character turnaround. This is the
                result of that, featuring my character Ifi. I'm especially happy
                with how my "lineless" style looks animated!
            </p>
        ),
        skills: ['animation'],
        tools: ['procreate'],
        type: 'gif',
        thumb: aotmIfiTurnaround2DThumb,
        thumbSize: '1:1',
        img: aotmIfiTurnaround2D
    },
    {
        title: 'Ifi Character Turnaround (3D)',
        id: 5,
        date: { m: 6, d: 8, y: 2022 },
        description: (
            <p>
                I wanted to try my hand at character modeling and rigging in 3D.
                So here's my first attempt, featuring my character Ifi. This was
                done in blender using some custom textures and shaders I made.
            </p>
        ),
        skills: ['animation', '3d'],
        tools: ['blender'],
        type: 'gif',
        thumb: aotmIfiTurnaround3DThumb,
        thumbSize: '1:1',
        img: aotmIfiTurnaround3D
    },
    {
        title: 'Fantasy Guild Poster',
        id: 6,
        date: { m: 6, d: 14, y: 2022 },
        description: (
            <p>
                Inspired by vintage war posters, this is a fantasy advertisement
                for the Lahaire guild, a group of famous adventurers. They are
                all characters in this project, including a younger Geche, Ifi's
                great-aunt!
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmLahaireThumb,
        thumbSize: '4:3',
        img: aotmLahaire
    },
    {
        title: 'Rozir Outfit Exploration',
        id: 8,
        date: { m: 12, d: 16, y: 2021 },
        description: (
            <p>
                In this project, I have a lot of different characters and
                settings. I wanted to explore how one of my characters, Rozir,
                would react in the various places the story takes place. As you
                can see, Rozir is not a fan of the cold!
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmRozirOutfitsThumb,
        thumbSize: '3:4',
        img: aotmRozirOutfits
    },
    {
        title: 'Syad Outfit Exploration',
        id: 10,
        date: { m: 1, d: 5, y: 2021 },
        description: (
            <p>
                In this project, I have a lot of different characters and
                settings. I wanted to explore how one of my characters, Syad,
                would react in the various places the story takes place. As you
                can see, Syad gets overheated easily!
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmSyadOutfitsThumb,
        thumbSize: '1:1',
        img: aotmSyadOutfits
    },
    {
        title: 'Titania Character Illustration',
        id: 11,
        date: { m: 12, d: 9, y: 2021 },
        description: (
            <p>
                I had a vision of a fantasy satyr woman named after the queen of
                fairies, and thus this illustration came to be! I ended up
                liking her so much that she's now a character in this project. I
                also used this piece to experiment with a "lineless" style of
                rendering using bold colors and high contrast.
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmTitaniaThumb,
        thumbSize: '3:4',
        img: aotmTitania
    },
    {
        title: 'Villain Animation',
        id: 12,
        date: { m: 11, d: 6, y: 2021 },
        description: (
            <p>
                While learning OpenToonz, I wanted to both learn how to animate
                hair in the wind, and experiment with mood lighting. So I
                designed and animated a character from this project, named
                Maricar, who would be a perfect fit for that vision!
            </p>
        ),
        skills: ['animation', 'art-direction', 'character-design'],
        tools: ['open-toonz', 'after-effects'],
        type: 'video',
        poster: aotmVillainAnimationPoster,
        thumb: aotmVillainAnimationThumb,
        thumbSize: '4:3',
        img: aotmVillainAnimation
    },
    {
        title: 'Air Mage Card',
        id: 31,
        date: { m: 9, d: 26, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for air magic, and I thought it fitting to have
                an eastern-style flying dragon on it, since they are often
                associated with air and wind in asian mythologies.
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardAirThumb,
        thumbSize: '3:4',
        img: mageCardAir
    },
    {
        title: 'Cold Mage Card',
        id: 32,
        date: { m: 9, d: 18, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for cold magic, and I wanted to represent a
                snow fox in a snowy landscape.
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardColdThumb,
        thumbSize: '3:4',
        img: mageCardCold
    },
    {
        title: 'Earth Mage Card (Animated)',
        id: 33,
        date: { m: 6, d: 22, y: 2022 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for earth magic, and I wanted to represent all
                the different kinds of earth (rock, dirt, gems) in one giant
                golem character. I also animated this to show how the card
                changes when in use by the caster. I had the idea that the earth
                magic "awakens" and so the rocks float.
            </p>
        ),
        skills: ['illustration', 'animation'],
        tools: ['procreate', 'illustrator'],
        type: 'gif',
        thumb: mageCardEarthThumb,
        thumbSize: '1:1',
        img: mageCardEarth
    },
    {
        title: 'Fire Mage Card',
        id: 34,
        date: { m: 9, d: 26, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for fire magic, and I wanted to represent a
                traditional firey dragon. Very much Maleficant vibes!
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardFireThumb,
        thumbSize: '3:4',
        img: mageCardFire
    },
    {
        title: 'Flora Mage Card (Animated)',
        id: 35,
        date: { m: 6, d: 17, y: 2022 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for earth magic, and I wanted to represent a
                lot of different kinds of plants, brought together by a tree
                spirit. I also animated this to show how the card changes when
                in use by the caster. I had the idea that the flora magic
                "awakens" and so the flowers bloom.
            </p>
        ),
        skills: ['illustration', 'animation'],
        tools: ['procreate', 'illustrator'],
        type: 'gif',
        thumb: mageCardFloraThumb,
        thumbSize: '1:1',
        img: mageCardFlora
    },
    {
        title: 'Water Mage Card',
        id: 36,
        date: { m: 9, d: 26, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for water magic, and what better to represent
                water than a mermaid?
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardWaterThumb,
        thumbSize: '3:4',
        img: mageCardWater
    },
    {
        title: 'Beyarsa Character Stats',
        id: 36,
        date: { m: 9, d: 13, y: 2021 },
        description: (
            <p>
                This project started out as an idea for a Dungeons and Dragons
                campaign, so I thought it only fitting to make some character
                art of my main characters with their associated D&D stats.
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: beyarsaStatsThumb,
        thumbSize: '3:4',
        img: beyarsaStats
    },
    {
        title: 'Fauna Card',
        id: 36,
        date: { m: 10, d: 5, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for animal magic, and I was inspired by Studio
                Ghibli films such as Princess Mononoke to represent a large
                deer.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: faunaThumb,
        thumbSize: '3:4',
        img: fauna
    },
    {
        title: 'Ifi Family Color Study',
        id: 36,
        date: { m: 2, d: 4, y: 2022 },
        description: (
            <p>
                In the pilot episode for this project, all of Ifi's family is
                shown within the first few minutes. I wanted to ensure that
                their color palettes were distinct but harmonious. Since they're
                farmers, I chose a lot of earthy and spring/summer-time colors.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: ifiFamilyColorStudyThumb,
        thumbSize: '3:4',
        img: ifiFamilyColorStudy
    },
    {
        title: 'Ifi Party Outfit',
        id: 36,
        date: { m: 1, d: 19, y: 2021 },
        description: (
            <p>
                I love designing different outfits and settings for my
                characters to be in, and I had a lot of fun creating this party
                outfit for Ifi.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: ifiOutfits01Thumb,
        thumbSize: '3:4',
        img: ifiOutfits01
    },
    {
        title: 'Ifi Sleep Outfit',
        id: 36,
        date: { m: 1, d: 19, y: 2021 },
        description: (
            <p>
                I love designing different outfits and settings for my
                characters to be in, and I had a lot of fun creating this
                nighttime outfit for Ifi.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: ifiOutfits02Thumb,
        thumbSize: '3:4',
        img: ifiOutfits02
    },
    {
        title: 'Ifi Desert Outfit',
        id: 36,
        date: { m: 1, d: 19, y: 2021 },
        description: (
            <p>
                I love designing different outfits and settings for my
                characters to be in. This is my interpretation of what Ifi might
                wear when traveling through the desert.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: ifiOutfits03Thumb,
        thumbSize: '3:4',
        img: ifiOutfits03
    },
    {
        title: 'Ifi Stats',
        id: 36,
        date: { m: 9, d: 2, y: 2021 },
        description: (
            <p>
                This project started out as an idea for a Dungeons and Dragons
                campaign, so I thought it only fitting to make some character
                art of my main characters with their associated D&D stats.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: ifiStatsThumb,
        thumbSize: '3:4',
        img: ifiStats
    },
    {
        title: 'Light Card',
        id: 36,
        date: { m: 9, d: 18, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for light magic, and I decided to make the card
                feature a large cat at sunrise.
            </p>
        ),
        skills: ['illustration', 'graphic-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: lightThumb,
        thumbSize: '3:4',
        img: light
    },
    {
        title: 'Lo Character Art',
        id: 36,
        date: { m: 12, d: 16, y: 2021 },
        description: (
            <p>
                This is my first character art for Lo, a goliath mage who serves
                as a bit of a mentor to Ifi. She's incredibly bookish, hence the
                glasses, and her design is inspired by both Nepalese and Inupak
                culture.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: loThumb,
        thumbSize: '3:4',
        img: lo
    },
    {
        title: 'Rozir Character Art',
        id: 36,
        date: { m: 11, d: 11, y: 2020 },
        description: (
            <p>
                This is the first character art I drew for Rozir, one of the
                main characters in my project. I wanted a moody, tough character
                who looks like a trained fighter but also a bit rebellious. I
                originally imagined him as a bard, but I later changed this to
                make him a fighter.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: rozirThumb,
        thumbSize: '3:4',
        img: rozir
    },
    {
        title: 'Rozir Stats',
        id: 36,
        date: { m: 9, d: 4, y: 2021 },
        description: (
            <p>
                This project started out as an idea for a Dungeons and Dragons
                campaign, so I thought it only fitting to make some character
                art of my main characters with their associated D&D stats.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: rozirStatsThumb,
        thumbSize: '3:4',
        img: rozirStats
    },
    {
        title: 'Shadow Card',
        id: 36,
        date: { m: 9, d: 30, y: 2020 },
        description: (
            <p>
                In Age of the Mage, I have a concept of mage cards; magical
                tarot-style cards which represent a particular type of magic.
                This is the card for light magic, and I decided to make the card
                feature a spooky looking shadow-dog in front of a dark vortex.
            </p>
        ),
        skills: ['illustration', 'graphic-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: shadowThumb,
        thumbSize: '3:4',
        img: shadow
    },
    {
        title: 'Syad Character Art',
        id: 36,
        date: { m: 11, d: 9, y: 2020 },
        description: (
            <p>
                This is the first character art I drew for Syad, one of the main
                characters in my project. I wanted a shy, kind-hearted character
                who was the team's medic.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: syadThumb,
        thumbSize: '3:4',
        img: syad
    },
    {
        title: 'Syad Stats',
        id: 36,
        date: { m: 9, d: 8, y: 2021 },
        description: (
            <p>
                This project started out as an idea for a Dungeons and Dragons
                campaign, so I thought it only fitting to make some character
                art of my main characters with their associated D&D stats.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: syadStatsThumb,
        thumbSize: '3:4',
        img: syadStats
    },
    {
        title: 'Cheo Stats',
        id: 36,
        date: { m: 9, d: 21, y: 2021 },
        description: (
            <p>
                This project started out as an idea for a Dungeons and Dragons
                campaign, so I thought it only fitting to make some character
                art of my main characters with their associated D&D stats.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: cheoStatsThumb,
        thumbSize: '3:4',
        img: cheoStats
    },
    {
        title: 'Geche Character Design Options',
        id: 36,
        date: { m: 2, d: 18, y: 2022 },
        description: (
            <p>
                When designing Ifi's great-aunt Geche, I had a few options of
                how I wanted her to look. Ultimately, I went with option E, but
                I had a lot of fun thinking through what she might look like.
            </p>
        ),
        skills: ['illustration', 'character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: gecheOptionsThumb,
        thumbSize: '3:4',
        img: gecheOptions
    }
];

let randomizedGallery = dataAotM.sort(() => Math.random() - 0.5);

const dataWriting = [
    {
        title: 'Pilot Episode - Part 01',
        id: 36,
        date: { m: 9, d: 9, y: 2022 },
        description: (
            <p>
                Age of the Mage is a story of Ifi Jebaswa, a young mage with a
                heart for adventure. This script is for a three-part pilot, and
                serves as the first part. This was my first foray into
                screenwriting, I wanted to show a bit about Ifi's life before
                her journey really begins.
            </p>
        ),
        skills: ['screenwriting'],
        tools: ['scenarist'],
        type: 'pdf',
        thumb: pilot01Thumb,
        thumbSize: '3:4',
        img: pilot01
    },
    {
        title: 'Pilot Episode - Part 02',
        id: 36,
        date: { m: 9, d: 14, y: 2022 },
        description: (
            <p>
                Age of the Mage is a story of Ifi Jebaswa, a young mage with a
                heart for adventure. This script is for a three-part pilot, and
                serves as the second part. In this script, I show a bit of the
                world Ifi will continue exploring, and set up a mysterious death
                that will leave a lasting impact.
            </p>
        ),
        skills: ['screenwriting'],
        tools: ['scenarist'],
        type: 'pdf',
        thumb: pilot02Thumb,
        thumbSize: '3:4',
        img: pilot02
    },
    {
        title: 'Pilot Episode - Part 03',
        id: 36,
        date: { m: 9, d: 14, y: 2022 },
        description: (
            <p>
                Age of the Mage is a story of Ifi Jebaswa, a young mage with a
                heart for adventure. This script is for a three-part pilot, and
                serves as the final part. In this script, I show more of Ifi's
                strength and her own growth as a mage, as well as end the pilot
                with an introduction of the remaining main characters.
            </p>
        ),
        skills: ['screenwriting'],
        tools: ['scenarist'],
        type: 'pdf',
        thumb: pilot03Thumb,
        thumbSize: '3:4',
        img: pilot03
    }
];

const dataStoryboards = [
    {
        title: 'Pilot Episode Animatic (Part 1)',
        id: 9,
        date: { m: 1, d: 8, y: 2023 },
        description: (
            <p>
                This storyboard covers the first part of the pilot episode. This
                was my first foray into storyboarding, and I learned a lot!
                ...Which might explain why the style is inconsistent throughout
                the video. And yes, all the voices here are my own!
            </p>
        ),
        skills: ['animation', 'art-direction', 'storyboarding'],
        tools: ['procreate', 'storyboard-pro'],
        type: 'video',
        wip: true,
        thumb: aotmStoryboardPilotThumb,
        poster: aotmStoryboardPilotPoster,
        thumbSize: '16:9',
        img: aotmStoryboardPilot
    },
    {
        title: 'Pilot Episode Animatic (Part 2)',
        id: 9,
        date: { m: 1, d: 8, y: 2023 },
        description: (
            <p>
                This storyboard covers the second part of the pilot episode.
                This piece of the pilot is still very early in development, so
                excuse the sudden cutoff!
            </p>
        ),
        skills: ['animation', 'art-direction', 'storyboarding'],
        tools: ['procreate', 'storyboard-pro'],
        type: 'video',
        wip: true,
        thumb: aotmStoryboardPilot02Thumb,
        poster: aotmStoryboardPilot02Poster,
        thumbSize: '16:9',
        img: aotmStoryboardPilot02
    },
    {
        title: 'Opening Song Animatic',
        id: 7,
        date: { m: 11, d: 2, y: 2022 },
        description: (
            <p>
                I wanted to try my hand at making an anime-inspired opening
                sequence. Using{' '}
                <a
                    href='https://www.youtube.com/watch?v=iBRH9Po3gw8'
                    target='_blank'
                    rel='noreferrer'>
                    music from My Hero Academia
                </a>
                , I imagined this fun, dynamic opening sequence for Age of the
                Mage. I hope to fully animated this sequence someday!
            </p>
        ),
        skills: ['animation', 'art-direction', 'storyboarding'],
        tools: ['storyboard-pro'],
        type: 'video',
        thumb: aotmOpeningSongThumb,
        poster: aotmOpeningSongPoster,
        thumbSize: '16:9',
        img: aotmOpeningSong
    }
];

export default randomizedGallery;
export { dataWriting, dataStoryboards };
