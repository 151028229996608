import React, { useState } from 'react';
import { NavLink, ScrollRestoration, useLocation } from 'react-router-dom';

import Sidebar from './sidebar';

const pages = [
    { name: 'Home', url: '/' },
    { name: 'Gallery', url: '/gallery' },
    { name: 'Projects', url: '/projects' },
    { name: 'Resume', url: '/resume.pdf', href: true },
    { name: 'Contact', url: '/#contact' }
];

function NavText(props) {
    const location = useLocation();

    return (
        <>
            <div className={'nav-text ' + props.className}>
                {pages.map((val, ind) => {
                    let isActive =
                        location.pathname === val.url ||
                        props.active === val.url;

                    return (
                        <div
                            key={ind}
                            className={isActive ? 'selected link' : 'link'}>
                            {val.href ? (
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={val.url}
                                    className={isActive ? 'selected' : ''}>
                                    {val.name}
                                </a>
                            ) : (
                                <NavLink
                                    to={val.url}
                                    className={isActive ? 'selected' : ''}>
                                    {val.name}
                                </NavLink>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

//--------------------------------------------------------------------------------

function Nav(props) {
    const location = useLocation();
    const [state, setState] = useState('closed');

    return (
        <nav className='nav'>
            {!props.noScroll && <ScrollRestoration />}
            <Sidebar state={state} setState={setState} icon='fa-solid fa-bars'>
                {pages.map((val, ind) => {
                    let isActive =
                        location.pathname === val.url ||
                        props.active === val.url;

                    return (
                        <div
                            key={ind}
                            className={isActive ? 'selected link' : 'link'}>
                            {val.href ? (
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={val.url}
                                    className={isActive ? 'selected' : ''}>
                                    <h5>{val.name}</h5>
                                </a>
                            ) : (
                                <NavLink
                                    onClick={() => {
                                        setState('closed');
                                    }}
                                    to={val.url}
                                    className={isActive ? 'selected' : ''}>
                                    <h5>{val.name}</h5>
                                </NavLink>
                            )}
                        </div>
                    );
                })}
            </Sidebar>
        </nav>
    );
}

export default Nav;
export { NavText };
