import React, { useEffect, useState } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route
} from 'react-router-dom';

import HomePage from './pages/home-page.js';
import GalleryPage from './pages/gallery-page.js';
import ProjectsPage from './pages/projects-page.js';
import AgeOfTheMage from './pages/projects/age-of-the-mage.js';
import SupremeCourtship from './pages/projects/supreme-courtship.js';
import RisingTides from './pages/projects/rising-tides.js';
import PlayingCards from './pages/projects/playing-cards.js';

import './_sass/styles.sass';
import Error404 from './pages/404.js';

function App() {
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFirstLoad(false);
        }, 2000);
    }, [setFirstLoad]);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path='/' element={<HomePage firstLoad={firstLoad} />} />
                <Route path='/gallery' element={<GalleryPage />} />
                <Route path='/projects' element={<ProjectsPage />} />
                <Route
                    path='/projects/age-of-the-mage'
                    element={<AgeOfTheMage />}
                />
                <Route
                    path='/projects/supreme-courtship'
                    element={<SupremeCourtship />}
                />
                <Route
                    path='/projects/rising-tides'
                    element={<RisingTides />}
                />
                <Route
                    path='/projects/playing-cards'
                    element={<PlayingCards />}
                />

                <Route path='*' element={<Error404 />} />
            </>
        )
    );

    return (
        <div className='App'>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
