import React from 'react';

import risingTidesTrailer from '../_img/projects/rising-tides/rising-tides-trailer.mp4';
import risingTidesTrailerThumb from '../_img/projects/rising-tides/thumbs/rising-tides-trailer.gif';
import risingTidesTrailerPoster from '../_img/projects/rising-tides/posters/rising-tides-trailer.png';
import carvingTiles from '../_img/projects/rising-tides/carving-tiles.gif';
import carvingTilesThumb from '../_img/projects/rising-tides/thumbs/carving-tiles.gif';
import hackathon from '../_img/projects/rising-tides/hackathon.png';
import hackathonThumb from '../_img/projects/rising-tides/thumbs/hackathon.png';
import map from '../_img/projects/rising-tides/map.png';
import mapThumb from '../_img/projects/rising-tides/thumbs/map.png';
import boardCloseup from '../_img/projects/rising-tides/board-closeup.png';
import boardCloseupThumb from '../_img/projects/rising-tides/thumbs/board-closeup.png';
import flipGreenNewDeal from '../_img/projects/rising-tides/flip-green-new-deal.gif';
import flipGreenNewDealThumb from '../_img/projects/rising-tides/thumbs/flip-green-new-deal.gif';

const dataRisingTides = [
    {
        title: 'Game Trailer',
        id: 201,
        date: { m: 5, d: 11, y: 2019 },
        description: (
            <p>
                Thrown together quickly during the last few minutes of the
                hackathon, this trailer shares the narrative behind Rising
                Tides. And yes, that's my voice in the video!
            </p>
        ),
        skills: ['animation', 'design'],
        tools: ['after-effects'],
        type: 'video',
        thumb: risingTidesTrailerThumb,
        thumbSize: '16:9',
        img: risingTidesTrailer,
        poster: risingTidesTrailerPoster
    },

    {
        title: 'Making of the Tiles',
        id: 202,
        date: { m: 5, d: 10, y: 2019 },
        description: (
            <p>
                During the hackathon, I was lucky to have access to a maker lab
                with a CNC carver. Using this, I cut and engraved acrylic tiles
                to represent the major U.S. cities in our game.
            </p>
        ),
        skills: ['material-design', 'design'],
        tools: ['maker-tools'],
        type: 'gif',
        thumb: carvingTilesThumb,
        thumbSize: '16:9',
        img: carvingTiles
    },

    {
        title: 'At the Hackathon',
        id: 203,
        date: { m: 5, d: 10, y: 2019 },
        description: (
            <p>
                A lot of work went into getting this game ready over a 48-hour
                period. I had to prototype rapidly and stress-test everything
                underwater! Given the short time, I'm incredibly proud of what I
                was able to accomplish!
            </p>
        ),
        skills: ['material-design', 'design'],
        tools: ['maker-tools'],
        type: 'image',
        thumb: hackathonThumb,
        thumbSize: '1:1',
        img: hackathon
    },

    {
        title: 'Board Map',
        id: 204,
        date: { m: 5, d: 10, y: 2019 },
        description: (
            <p>
                I designed the layout of the map and determined how to translate
                things like population density and elevation into our tile
                format. Here, black tiles represent the most populous cities,
                which are more at risk of climate disasters. Each tile also has
                a certain number of riser tiles below it to represent their
                relative elevation.
            </p>
        ),
        skills: ['material-design', 'design'],
        tools: ['maker-tools'],
        type: 'image',
        thumb: mapThumb,
        thumbSize: '4:3',
        img: map
    },

    {
        title: 'Board during Gameplay',
        id: 205,
        date: { m: 5, d: 10, y: 2019 },
        description: (
            <p>
                I designed each tile so that it had space for two player tokens
                to slot into. In addition to the tiles and player markers, we
                also had various tokens to represent resources and disasters,
                such as the red tile which represented a fire disaster that
                rendered the city inaccessible.
            </p>
        ),
        skills: ['material-design', 'design'],
        tools: ['maker-tools'],
        type: 'image',
        thumb: boardCloseupThumb,
        thumbSize: '4:3',
        img: boardCloseup
    },

    {
        title: 'Green New Deal Initiatives',
        id: 206,
        date: { m: 5, d: 10, y: 2019 },
        description: (
            <p>
                The end goal of the gameplay was to enact at least three green
                new deal initiatives. Players would do this by collaboratively
                cashing-in their collected resources. I wanted to use color to
                show the positive outcome of enacting these initiatives, hence
                turning the card over to reveal the "green" in the green new
                deal!
            </p>
        ),
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: flipGreenNewDealThumb,
        thumbSize: '4:3',
        img: flipGreenNewDeal
    }
];

let randomizedGallery = dataRisingTides.sort(() => Math.random() - 0.5);

export default randomizedGallery;
