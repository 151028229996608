import React from 'react';
import { Link } from 'react-router-dom';

function CircleBtn(props) {
    let content = <i className={props.icon} />;
    return (
        <>
            {props.href && (
                <a
                    className={'circle-btn ' + props.className}
                    target={props.target || '_blank'}
                    rel='noreferrer'
                    href={props.href}>
                    {content}
                </a>
            )}
            {props.to && (
                <Link className={'circle-btn ' + props.className} to={props.to}>
                    {content}
                </Link>
            )}
            {props.type && (
                <button
                    type={props.type}
                    className={'circle-btn ' + props.className}
                    onClick={props.onClick}>
                    {content}
                </button>
            )}
        </>
    );
}

//---------------------------------------------------------------

function TextBtn(props) {
    let content = (
        <>
            {props.leftIcon && <i className={props.leftIcon} />}
            <h5>{props.text}</h5>
            {props.rightIcon && <i className={props.rightIcon} />}
        </>
    );

    return (
        <>
            {props.type && (
                <button
                    className={'text-btn ' + props.className}
                    type={props.type}
                    onClick={props.onClick}>
                    {content}
                </button>
            )}
            {props.href && (
                <a
                    className={'text-btn ' + props.className}
                    target={props.target || '_blank'}
                    rel='noreferrer'
                    href={props.href}>
                    {content}
                </a>
            )}
            {props.to && (
                <Link className={'text-btn ' + props.className} to={props.to}>
                    {content}
                </Link>
            )}
        </>
    );
}

// ------------------------------------------------------------

function StickerBtn(props) {
    let content = (
        <>
            <img
                className='backing-img'
                draggable={false}
                src={props.imgBack}
                alt=''
            />
            <img
                className='main-img'
                draggable={false}
                src={props.img}
                alt={props.alt}
            />
        </>
    );

    return (
        <>
            {props.href && (
                <a
                    className={'sticker-btn ' + props.className}
                    target={props.target || '_blank'}
                    rel='noreferrer'
                    href={props.href}>
                    {content}
                </a>
            )}
            {props.to && (
                <Link
                    className={'sticker-btn ' + props.className}
                    to={props.to}>
                    {content}
                </Link>
            )}
        </>
    );
}

// ------------------------------------------------------------

function SocialBtns(props) {
    return (
        <>
            <CircleBtn
                className={props.className}
                href='https://www.instagram.com/sore.bore/'
                icon='fa-brands fa-instagram'
            />
            <CircleBtn
                className={props.className}
                href='https://www.tiktok.com/@sore.bore'
                icon='fa-brands fa-tiktok'
            />
            <CircleBtn
                className={props.className}
                href='https://www.youtube.com/channel/UCbt5vPU2co39-GEMDIGGlCQ'
                icon='fa-brands fa-youtube'
            />
        </>
    );
}

// ------------------------------------------------------------

function GalleryBtn(props) {
    let random = Math.random() * 4 - 2;
    return (
        <button
            style={{ transform: 'rotate(' + random + 'deg)' }}
            className='gallery-btn'
            onClick={props.handleClick}
            key={props.hasKey}>
            <img src={props.src} alt={props.alt} />
        </button>
    );
}

// ------------------------------------------------------------

function PortfolioBtn(props) {
    let random = Math.random() * 4 - 2;
    return (
        <Link
            style={{ transform: 'rotate(' + random + 'deg)' }}
            className='portfolio-btn'
            to={props.to}>
            <img src={props.src} alt={props.title} />

            <h5>
                <span>{props.title}</span>
                <i className='fa-solid fa-hand-point-right' />
            </h5>
        </Link>
    );
}

export { CircleBtn, TextBtn, StickerBtn, SocialBtns, GalleryBtn, PortfolioBtn };
