import { useEffect } from 'react';

function useKeypress(key, handleKeyPress) {
    useEffect(() => {
        function handleAnyKeyPress(event) {
            // if the key matches key, run handleKeyPress
            if (event.key === key) handleKeyPress();
        }

        // Bind the event listener
        document.addEventListener('keydown', handleAnyKeyPress);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('keydown', handleAnyKeyPress);
        };
    }, [key, handleKeyPress]);
}

export default useKeypress;
