import React from 'react';

import Breadcrumbs from '../components/breadcrumbs';
import Footer from '../components/footer';
import Nav from '../components/nav';
import { PortfolioBtn, TextBtn } from '../components/buttons';

import AotMHero from '../_img/projects/age-of-the-mage/hero.png';
import RTHero from '../_img/projects/rising-tides/hero.png';
import SCHero from '../_img/projects/supreme-courtship/hero.png';
import PCHero from '../_img/projects/playing-cards/hero.png';
import useDocumentTitle from '../util/use-document-title';

function ProjectsPage(props) {
    useDocumentTitle('Projects');

    return (
        <div className='page'>
            <Nav />
            <section className='project-grid-container'>
                <div className='container'>
                    <Breadcrumbs />
                    <div className='section-heading with-btn'>
                        <h1>Projects</h1>
                        <TextBtn
                            to='/gallery'
                            text='Switch to Gallery'
                            leftIcon='fa-solid fa-rotate'
                        />
                    </div>

                    <div className='project-grid'>
                        <div className='project-item'>
                            <PortfolioBtn
                                to='/projects/age-of-the-mage'
                                src={AotMHero}
                                title='Age of the Mage'
                            />
                        </div>

                        <div className='project-item'>
                            <PortfolioBtn
                                to='/projects/supreme-courtship'
                                src={SCHero}
                                title='Supreme Courtship'
                            />
                        </div>

                        <div className='project-item'>
                            <PortfolioBtn
                                to='/projects/rising-tides'
                                src={RTHero}
                                title='Rising Tides'
                            />
                        </div>

                        <div className='project-item'>
                            <PortfolioBtn
                                to='/projects/playing-cards'
                                src={PCHero}
                                title='Playing Cards'
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ProjectsPage;
