import React, { useState } from 'react';
import Footer from '../../components/footer';
import Nav from '../../components/nav';
import {
    Project,
    ProjectSectionSideBySide,
    ProjectGallery,
    ProjectSectionStacked
} from '../../components/projects/project';

import dataAotM, {
    dataWriting,
    dataStoryboards
} from '../../_data/data-age-of-the-mage';
import ifiStats from '../../_img/projects/age-of-the-mage/Ifi-Stats.png';
import characterDesign from '../../_img/projects/age-of-the-mage/character-design.png';
import worldBuilding from '../../_img/projects/age-of-the-mage/world-building.png';
import characters from '../../_img/gallery/thumbs/aotm-character-intros.gif';
import turnaround from '../../_img/gallery/thumbs/aotm-ifi-turnaround-3D.gif';

import { Link } from 'react-router-dom';
import useDocumentTitle from '../../util/use-document-title';

function AgeOfTheMage(props) {
    useDocumentTitle('Age of the Mage');

    let sections = [
        { title: 'The Idea', id: 'idea' },
        { title: 'Character Design', id: 'character-design' },
        { title: 'World Building', id: 'world-building' },
        { title: 'Screenwriting', id: 'screenwriting' },
        { title: 'Storyboards', id: 'storyboards' },
        { title: '2D Animation', id: '2d-animation' },
        { title: '3D Animation', id: '3d-exploration' },
        { title: 'Pitch Deck', id: 'pitch-deck' },
        { title: 'Gallery', id: 'gallery' }
    ];

    const random = () => {
        return Math.random() * 4 - 2;
    };

    const [lightbox, setLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    const [screenLightbox, setScreenLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    const [storyLightbox, setStoryLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    return (
        <div className='page'>
            <Nav />
            <Project
                title='Age of the Mage'
                sections={sections}
                description='Animated series pitch development and worldbuilding'
                notice='This project is still in development! Check back soon for updates.'>
                <ProjectSectionSideBySide
                    id={sections[0].id}
                    title={sections[0].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={ifiStats}
                            alt={sections[0].title}
                        />
                    }
                    right={
                        <p>
                            What started out as an idea for a D&D campaign
                            blossomed into a pitch for an animated series as I
                            fell in love with the characters and setting I had
                            created. Through the process of developing the
                            pitch, I learned much about screenwriting, 2D and 3D
                            animation, storyboarding, and more! The project is
                            very much still in development, but I hope to see it
                            on the screen someday.
                        </p>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[1].id}
                    title={sections[1].title}
                    titleOn='left'
                    right={
                        <img src={characterDesign} alt={sections[1].title} />
                    }
                    left={
                        <p>
                            I love designing characters, and I wanted each
                            character to have a unique shape language, color
                            palette and body type. Many of my characters have
                            gone through multiple versions of design and
                            clothing options, which you see in the{' '}
                            <Link to='#gallery'>gallery</Link>.
                        </p>
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[2].id}
                    title={sections[2].title}
                    titleOn='right'
                    left={<img src={worldBuilding} alt={sections[2].title} />}
                    right={
                        <p>
                            Possibly my favorite part of any story is the
                            world-building. I've made maps, defined card-based
                            magic systems, figured out different biomes and
                            settings, and developed a robust world history to
                            help fuel the story and character motivations.
                        </p>
                    }
                />

                <ProjectGallery
                    id={sections[3].id}
                    title={sections[3].title}
                    description={
                        <p>
                            For this project, I wanted to attempt to write a
                            pilot episode. This was my first major foray into
                            screenwriting, and I hope to do much more in the
                            future! You can read through the three-part pilot
                            screenplay below.
                        </p>
                    }
                    items={dataWriting}
                    lightbox={screenLightbox}
                    setLightbox={setScreenLightbox}
                />

                <ProjectGallery
                    id={sections[4].id}
                    title={sections[4].title}
                    description={
                        <p>
                            Working with Storyboard Pro, I made the following
                            three animatics for the show; two of them follow the
                            pilot script, and one imagines a possible opening
                            for the show.
                        </p>
                    }
                    items={dataStoryboards}
                    lightbox={storyLightbox}
                    setLightbox={setStoryLightbox}
                />

                <ProjectSectionSideBySide
                    id={sections[5].id}
                    title={sections[5].title}
                    titleOn='right'
                    right={
                        <p>
                            In addition to storyboards, I also practiced 2D
                            frame-by-frame animation. I have a deep love and
                            respect for 2D animation, and I hope to learn more
                            about it! You can see more of my short animations in
                            the <Link to='#gallery'>gallery</Link>.
                        </p>
                    }
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={characters}
                            alt={sections[5].title}
                        />
                    }
                />

                <ProjectSectionSideBySide
                    id={sections[6].id}
                    title={sections[6].title}
                    titleOn='left'
                    left={
                        <p>
                            Using Blender, I learned the basics of character
                            modeling and rigging in order to represent my main
                            character Ifi in 3D! I even made my own shaders and
                            textures to get the aesthetic I wanted. I
                            experimented with building some some 3D environments
                            as well.
                        </p>
                    }
                    right={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={turnaround}
                            alt={sections[6].title}
                        />
                    }
                />

                <ProjectSectionStacked
                    title={sections[7].title}
                    bottom={
                        <p>
                            Eventually, I hope to complete a pitch deck for this
                            animated series project, complete with episode
                            animatics, character overviews, and examples of
                            setting. This project is still in development, so
                            check back later to see my pitch deck progress!
                        </p>
                    }
                />

                <ProjectGallery
                    items={dataAotM}
                    lightbox={lightbox}
                    setLightbox={setLightbox}
                />
            </Project>
            <Footer className='project-footer' />
        </div>
    );
}

export default AgeOfTheMage;
