// be sure to match the order of secitons here!
const galleryFilterKeys = ['skills', 'type'];
const galleryFilter = [
    {
        text: 'Skills',
        name: 'skills',
        options: [
            { text: 'Animation', name: 'animation', selected: false },
            {
                text: 'Character Design',
                name: 'character-design',
                selected: false
            },
            { text: 'Graphic Design', name: 'design', selected: false },
            {
                text: 'Illustration',
                name: 'illustration',
                selected: false
            },
            {
                text: 'Storyboarding',
                name: 'storyboarding',
                selected: false
            }
        ]
    },
    {
        text: 'Type',
        name: 'type',
        options: [
            { text: 'Image', name: 'image', selected: false },
            { text: 'Gif', name: 'gif', selected: false },
            { text: 'Video', name: 'video', selected: false }
        ]
    }
];

const projectFilter = [];

export { galleryFilter, galleryFilterKeys, projectFilter };
