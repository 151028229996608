import React from 'react';
import { Link } from 'react-router-dom';

// TODO: Add
// --- Sci-Fi Woman Character
// --- Still Life

import aotmBeyarsaCharacter from '../_img/gallery/aotm-beyarsa-character.png';
import aotmBeyarsaCharacterThumb from '../_img/gallery/thumbs/aotm-beyarsa-character.png';
import aotmBardPoster from '../_img/gallery/aotm-bard-poster.png';
import aotmBardPosterThumb from '../_img/gallery/thumbs/aotm-bard-poster.png';
import aotmCharacterIntros from '../_img/gallery/aotm-character-intros.mp4';
import aotmCharacterIntrosThumb from '../_img/gallery/thumbs/aotm-character-intros.gif';
import aotmCharacterIntrosPoster from '../_img/gallery/posters/aotm-character-intros.png';
import aotmIfiExpressions from '../_img/gallery/aotm-ifi-expressions.png';
import aotmIfiExpressionsThumb from '../_img/gallery/thumbs/aotm-ifi-expressions.png';
import aotmIfiModel from '../_img/gallery/aotm-ifi-model.png';
import aotmIfiModelThumb from '../_img/gallery/thumbs/aotm-ifi-model.png';
import aotmIfiTurnaround2D from '../_img/gallery/aotm-ifi-turnaround-2D.gif';
import aotmIfiTurnaround2DThumb from '../_img/gallery/thumbs/aotm-ifi-turnaround-2D.gif';
import aotmIfiTurnaround3D from '../_img/gallery/aotm-ifi-turnaround-3D.gif';
import aotmIfiTurnaround3DThumb from '../_img/gallery/thumbs/aotm-ifi-turnaround-3D.gif';
import aotmLahaire from '../_img/gallery/aotm-lahaire.png';
import aotmLahaireThumb from '../_img/gallery/thumbs/aotm-lahaire.png';
import aotmOpeningSong from '../_img/gallery/aotm-opening-song.mp4';
import aotmOpeningSongThumb from '../_img/gallery/thumbs/aotm-opening-song.gif';
import aotmOpeningSongPoster from '../_img/gallery/posters/aotm-opening-song.png';
import aotmRozirOutfits from '../_img/gallery/aotm-rozir-outfits.png';
import aotmRozirOutfitsThumb from '../_img/gallery/thumbs/aotm-rozir-outfits.png';
import aotmStoryboardPilot from '../_img/gallery/aotm-storyboard-pilot.mp4';
import aotmStoryboardPilotThumb from '../_img/gallery/thumbs/aotm-storyboard-pilot.gif';
import aotmStoryboardPilotPoster from '../_img/gallery/posters/aotm-storyboard-pilot.png';
import aotmStoryboardPilot02 from '../_img/gallery/aotm-storyboard-pilot-02.mp4';
import aotmStoryboardPilot02Thumb from '../_img/gallery/thumbs/aotm-storyboard-pilot-02.gif';
import aotmStoryboardPilot02Poster from '../_img/gallery/posters/aotm-storyboard-pilot-02.png';
import aotmSyadOutfits from '../_img/gallery/aotm-syad-outfits.png';
import aotmSyadOutfitsThumb from '../_img/gallery/thumbs/aotm-syad-outfits.png';
import aotmTitania from '../_img/gallery/aotm-titania.png';
import aotmTitaniaThumb from '../_img/gallery/thumbs/aotm-titania.png';
import aotmVillainAnimation from '../_img/gallery/aotm-villain-animation.mp4';
import aotmVillainAnimationThumb from '../_img/gallery/thumbs/aotm-villain-animation.gif';
import aotmVillainAnimationPoster from '../_img/gallery/posters/aotm-villain-animation.png';
import characterCharoite from '../_img/gallery/character-charoite.png';
import characterCharoiteThumb from '../_img/gallery/thumbs/character-charoite.png';
import characterConcept01 from '../_img/gallery/character-concept-01.png';
import characterConcept01Thumb from '../_img/gallery/thumbs/character-concept-01.png';
import characterDio from '../_img/gallery/character-dio.png';
import characterDioThumb from '../_img/gallery/thumbs/character-dio.png';
import characterDragonbourne from '../_img/gallery/character-dragonbourne.png';
import characterDragonbourneThumb from '../_img/gallery/thumbs/character-dragonbourne.png';
import characterDwarf from '../_img/gallery/character-dwarf.png';
import characterDwarfThumb from '../_img/gallery/thumbs/character-dwarf.png';
import characterForged from '../_img/gallery/character-forged.png';
import characterForgedThumb from '../_img/gallery/thumbs/character-forged.png';
import characterGoblin from '../_img/gallery/character-goblin.png';
import characterGoblinThumb from '../_img/gallery/thumbs/character-goblin.png';
import characterSketches from '../_img/gallery/character-sketches.png';
import characterSketchesThumb from '../_img/gallery/thumbs/character-sketches.png';
import characterHoneyHeist from '../_img/gallery/characters-honey-heist.png';
import characterHoneyHeistThumb from '../_img/gallery/thumbs/characters-honey-heist.png';
import characterSupremeCourtship from '../_img/gallery/characters-supreme-courtship.png';
import characterSupremeCourtshipThumb from '../_img/gallery/thumbs/characters-supreme-courtship.png';
import cityGirl from '../_img/gallery/city-girl.png';
import cityGirlThumb from '../_img/gallery/thumbs/city-girl.png';
//import eyesAndSmiles from '../_img/gallery/eyes-and-smiles.png';
//import eyesAndSmilesThumb from '../_img/gallery/thumbs/eyes-and-smiles.png';
import fanartBloodkeep from '../_img/gallery/fanart-bloodkeep.png';
import fanartBloodkeepThumb from '../_img/gallery/thumbs/fanart-bloodkeep.png';
import fanartSailorMoon from '../_img/gallery/fanart-sailor-moon.png';
import fanartSailorMoonThumb from '../_img/gallery/thumbs/fanart-sailor-moon.png';
import fanartSymmetra from '../_img/gallery/fanart-symmetra.png';
import fanartSymmetraThumb from '../_img/gallery/thumbs/fanart-symmetra.png';
//import gameUIDesign from '../_img/gallery/game-ui-design.png';
//import gameUIDesignThumb from '../_img/gallery/thumbs/game-ui-design.png';
import hylianCourtCardBack from '../_img/gallery/hylian-court-card-back.png';
import hylianCourtCardBackThumb from '../_img/gallery/thumbs/hylian-court-card-back.png';
import logoJustUsGames from '../_img/gallery/logo-just-us-games.png';
import logoJustUsGamesThumb from '../_img/gallery/thumbs/logo-just-us-games.png';
import logoWomenGameDev from '../_img/gallery/logo-women-game-dev.PNG';
import logoWomenGameDevThumb from '../_img/gallery/thumbs/logo-women-game-dev.PNG';
import mageCardAir from '../_img/gallery/mage-card-air.png';
import mageCardAirThumb from '../_img/gallery/thumbs/mage-card-air.png';
import mageCardCold from '../_img/gallery/mage-card-cold.png';
import mageCardColdThumb from '../_img/gallery/thumbs/mage-card-cold.png';
import mageCardEarth from '../_img/gallery/mage-card-earth.gif';
import mageCardEarthThumb from '../_img/gallery/thumbs/mage-card-earth.gif';
import mageCardFire from '../_img/gallery/mage-card-fire.png';
import mageCardFireThumb from '../_img/gallery/thumbs/mage-card-fire.png';
import mageCardFlora from '../_img/gallery/mage-card-flora.gif';
import mageCardFloraThumb from '../_img/gallery/thumbs/mage-card-flora.gif';
import mageCardWater from '../_img/gallery/mage-card-water.png';
import mageCardWaterThumb from '../_img/gallery/thumbs/mage-card-water.png';
import makecode from '../_img/gallery/makecode.png';
import makecodeThumb from '../_img/gallery/thumbs/makecode.png';
import moonPrismCardBack from '../_img/gallery/moon-prism-card-back.png';
import moonPrismCardBackThumb from '../_img/gallery/thumbs/moon-prism-card-back.png';
//import moonPrismJack from '../_img/gallery/moon-prism-jack.png';
//import moonPrismJackThumb from '../_img/gallery/thumbs/moon-prism-jack.png';
import oneLastJobCrew from '../_img/gallery/one-last-job-crew.png';
import oneLastJobCrewThumb from '../_img/gallery/thumbs/one-last-job-crew.png';
import oneLastJobLocation from '../_img/gallery/thumbs/one-last-job-location.png';
import oneLastJobLocationThumb from '../_img/gallery/thumbs/one-last-job-location.png';
import portrait01 from '../_img/gallery/portrait-01.png';
import portrait01Thumb from '../_img/gallery/thumbs/portrait-01.png';
import portrait02 from '../_img/gallery/portrait-02.png';
import portrait02Thumb from '../_img/gallery/thumbs/portrait-02.png';
import portrait03 from '../_img/gallery/portrait-03.png';
import portrait03Thumb from '../_img/gallery/thumbs/portrait-03.png';
//import portrait04 from '../_img/gallery/portrait-04.png';
//import portrait04Thumb from '../_img/gallery/thumbs/portrait-04.png';
import supremeCourtshipTrailer from '../_img/gallery/supreme-courtship-trailer.mp4';
import supremeCourtshipTrailerThumb from '../_img/gallery/thumbs/supreme-courtship-trailer.gif';
import supremeCourtshipTrailerPoster from '../_img/gallery/posters/supreme-courtship-trailer.png';
import sketchHands from '../_img/gallery/sketch-hands.png';
import sketchHandsThumb from '../_img/gallery/thumbs/sketch-hands.png';
import sketchFeet from '../_img/gallery/sketch-feet.png';
import sketchFeetThumb from '../_img/gallery/thumbs/sketch-feet.png';
import supremeCourtshipLogo from '../_img/gallery/supreme-courtship-logo.png';
import supremeCourtshipLogoThumb from '../_img/gallery/thumbs/supreme-courtship-logo.png';
import supremeCourtshipStoryboard from '../_img/gallery/supreme-courtship-storyboard.gif';
import supremeCourtshipStoryboardThumb from '../_img/gallery/thumbs/supreme-courtship-storyboard.gif';
import makecodeHourOfCode from '../_img/gallery/makecode-hour-of-code.gif';
import makecodeHourOfCodeThumb from '../_img/gallery/thumbs/makecode-hour-of-code.gif';

const dataGallery = [
    {
        title: 'Beyarsa Character Expressions',
        id: 50,
        date: { m: 3, d: 30, y: 2023 },
        description: (
            <p>
                This is a character reference and expression sheet I drew for my
                fantasy Orc Paladin character from my
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. I wanted to show off her weapons and personality; she's
                a hardy no-nonsense leader who wields a handaxe and arcane
                shield!
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmBeyarsaCharacterThumb,
        thumbSize: '1:1',
        img: aotmBeyarsaCharacter,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Fantasy Bards Poster',
        id: 0,
        date: { m: 6, d: 28, y: 2022 },
        description: (
            <p>
                Inspired by a{' '}
                <a
                    href='https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/cordoba-feria-de-otono-unknown.jpg'
                    target='_blank'
                    rel='noreferrer'>
                    vintage Spanish poster
                </a>
                , this is a fantasy advertisement for a famous bard duo. They
                are both characters in my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project.
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate', 'photoshop'],
        type: 'image',
        thumb: aotmBardPosterThumb,
        thumbSize: '3:4',
        img: aotmBardPoster,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Character Intro Animation',
        id: 1,
        date: { m: 11, d: 29, y: 2022 },
        description: (
            <p>
                This short animation is part of an anime-inspired opening
                sequence for my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. I wanted to quickly show a glimpse of each character's
                personality, and tease their role in the story. This animation
                is only in the sketch stage, as the project is still in
                development, but check back later for updates!
            </p>
        ),
        skills: ['character-design', 'animation', 'art-direction'],
        tools: ['procreate', 'storyboard-pro'],
        type: 'video',
        wip: true,
        thumb: aotmCharacterIntrosThumb,
        poster: aotmCharacterIntrosPoster,
        thumbSize: '16:9',
        img: aotmCharacterIntros,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Ifi Expressions Sketches',
        id: 2,
        date: { m: 11, d: 20, y: 2020 },
        description: (
            <p>
                A quick practice of some expressions and outfits of Ifi, the
                main character from my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmIfiExpressionsThumb,
        thumbSize: '3:4',
        img: aotmIfiExpressions,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Ifi Character Art',
        id: 3,
        date: { m: 11, d: 8, y: 2020 },
        description: (
            <p>
                This is the first character art I drew for Ifi, the main
                character in my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. I wanted a fun, adventurous main character, and was
                inspired by Steven Universe and Sailor Moon.
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmIfiModelThumb,
        thumbSize: '4:3',
        img: aotmIfiModel,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Ifi Character Turnaround (2D)',
        id: 4,
        date: { m: 1, d: 11, y: 2022 },
        description: (
            <p>
                As an early venture into the world of 2D animation, I made this
                fully-rendered character turnaround featuring my character Ifi
                from my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. I'm especially happy with how my "lineless" style looks
                animated!
            </p>
        ),
        skills: ['animation'],
        tools: ['procreate'],
        type: 'gif',
        thumb: aotmIfiTurnaround2DThumb,
        thumbSize: '1:1',
        img: aotmIfiTurnaround2D,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Ifi Character Turnaround (3D)',
        id: 5,
        date: { m: 6, d: 8, y: 2022 },
        description: (
            <p>
                I wanted to try my hand at character modeling and rigging in 3D.
                So here's my first attempt, featuring my character Ifi from my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. This was done in blender using some custom textures and
                shaders I made.
            </p>
        ),
        skills: ['animation', '3d'],
        tools: ['blender'],
        type: 'gif',
        thumb: aotmIfiTurnaround3DThumb,
        thumbSize: '1:1',
        img: aotmIfiTurnaround3D,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Fantasy Guild Poster',
        id: 6,
        date: { m: 6, d: 14, y: 2022 },
        description: (
            <p>
                Inspired by vintage war posters, this is a fantasy advertisement
                for the Lahaire guild, a group of famous adventurers. They are
                all characters in my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project.
            </p>
        ),
        skills: ['character-design', 'illustration', 'design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmLahaireThumb,
        thumbSize: '4:3',
        img: aotmLahaire,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Opening Song Animatic',
        id: 7,
        date: { m: 11, d: 2, y: 2022 },
        description: (
            <p>
                For my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I wanted to try my hand at making an anime-inspired
                opening sequence. Using{' '}
                <a
                    href='https://www.youtube.com/watch?v=iBRH9Po3gw8'
                    target='_blank'
                    rel='noreferrer'>
                    music from My Hero Academia
                </a>
                , I imagined this fun, dynamic opening sequence for an animated
                series.
            </p>
        ),
        skills: ['animation', 'storyboarding', 'art-direction'],
        tools: ['storyboard-pro'],
        type: 'video',
        thumb: aotmOpeningSongThumb,
        poster: aotmOpeningSongPoster,
        thumbSize: '16:9',
        img: aotmOpeningSong,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Rozir Outfit Exploration',
        id: 8,
        date: { m: 12, d: 16, y: 2021 },
        description: (
            <p>
                For my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a lot of different characters and settings. I
                wanted to explore how one of my characters, Rozir, would react
                in the various places the story takes place. As you can see,
                Rozir is not a fan of the cold!
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmRozirOutfitsThumb,
        thumbSize: '3:4',
        img: aotmRozirOutfits,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Pilot Episode Animatic (Part 1)',
        id: 9,
        date: { m: 1, d: 8, y: 2023 },
        description: (
            <p>
                This is a rough, in-progress animatic of the pilot episode for
                my <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. I learned a lot about both animatics and storyboarding,
                which might explain why the style is inconsistent throughout the
                video. And yes, all the voices here are my own!
            </p>
        ),
        skills: [
            'animation',
            'art-direction',
            'storyboarding',
            'screenwriting'
        ],
        tools: ['procreate', 'storyboard-pro'],
        type: 'video',
        wip: true,
        thumb: aotmStoryboardPilotThumb,
        poster: aotmStoryboardPilotPoster,
        thumbSize: '16:9',
        img: aotmStoryboardPilot,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Pilot Episode Animatic (Part 2)',
        id: 10,
        date: { m: 1, d: 8, y: 2023 },
        description: (
            <p>
                This is a rough, in-progress animatic of the pilot episode for
                my <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. This piece of the pilot is still very early in
                development, so excuse the sudden cutoff!
            </p>
        ),
        skills: [
            'animation',
            'art-direction',
            'storyboarding',
            'screenwriting'
        ],
        tools: ['procreate', 'storyboard-pro'],
        type: 'video',
        wip: true,
        thumb: aotmStoryboardPilot02Thumb,
        poster: aotmStoryboardPilot02Poster,
        thumbSize: '16:9',
        img: aotmStoryboardPilot02,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Syad Outfit Exploration',
        id: 11,
        date: { m: 1, d: 5, y: 2021 },
        description: (
            <p>
                For my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a lot of different characters and settings. I
                wanted to explore how one of my characters, Syad, would react in
                the various places the story takes place. As you can see, Syad
                gets overheated easily!
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmSyadOutfitsThumb,
        thumbSize: '1:1',
        img: aotmSyadOutfits,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Titania Character Art',
        id: 12,
        date: { m: 12, d: 9, y: 2021 },
        description: (
            <p>
                I had a vision of a fantasy satyr woman named after the queen of
                fairies, and thus this illustration came to be! I ended up
                liking her so much that she's now a character in my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project. I also used this piece to experiment with a "lineless"
                style of rendering using bold colors and high contrast.
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: aotmTitaniaThumb,
        thumbSize: '3:4',
        img: aotmTitania,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Villain Animation',
        id: 13,
        date: { m: 11, d: 6, y: 2021 },
        description: (
            <p>
                While learning OpenToonz, I wanted to both learn how to animate
                hair in the wind, and experiment with mood lighting. So I
                designed and animated a character from my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project who would be a perfect fit for that vision!
            </p>
        ),
        skills: ['animation', 'art-direction', 'character-design'],
        tools: ['open-toonz', 'after-effects'],
        type: 'video',
        poster: aotmVillainAnimationPoster,
        thumb: aotmVillainAnimationThumb,
        thumbSize: '4:3',
        img: aotmVillainAnimation,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Charoite Character Art',
        id: 14,
        date: { m: 3, d: 6, y: 2020 },
        description: (
            <p>
                A character design commission for someone who wanted a "wolf
                girl sailor scout" with ice powers. I wanted to bring in a lot
                of white fur and fluffy hair to bring in that wolf/dog feel
                (think giant Samoyed), and use the hair bows to mimic dog ears.
                The client's favorite color was purple, so I used a lot of that
                in the design as well.
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: characterCharoiteThumb,
        thumbSize: '3:4',
        img: characterCharoite
    },
    {
        title: 'Character Concept Sketch',
        id: 15,
        date: { m: 4, d: 14, y: 2019 },
        description: (
            <p>
                A rough character sketch that I ended up being quite fond of. I
                wanted her outfit to be both fashionable and functional,
                inspired by modern street-wear.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterConcept01Thumb,
        thumbSize: '1:1',
        img: characterConcept01
    },
    {
        title: 'Aarokocra Character Art',
        id: 16,
        date: { m: 3, d: 13, y: 2020 },
        description: (
            <p>
                This was an Aarokocra (bird-person) ranger character I played in
                a Dungeons and Dragons game. I love the idea of a bird archer,
                and ultimately I carried that idea over into my character Cheo,
                in my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project!
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterDioThumb,
        thumbSize: '3:4',
        img: characterDio
    },
    {
        title: 'Dragonborn Character Art',
        id: 17,
        date: { m: 6, d: 21, y: 2020 },
        description: (
            <p>
                This is a dragonborn cleric character I designed. I was very
                inspired by{' '}
                <a
                    href='https://www.instagram.com/badbucket'
                    target='_blank'
                    rel='noreferrer'>
                    Conner Fawcett's illustration style
                </a>
                , so I tried to emulate some of those techniques in my piece.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterDragonbourneThumb,
        thumbSize: '3:4',
        img: characterDragonbourne
    },
    {
        title: 'Dwarf Character Art',
        id: 18,
        date: { m: 6, d: 21, y: 2020 },
        description: (
            <p>
                This is a dwarf fighter character I designed. I was very
                inspired by{' '}
                <a
                    href='https://www.instagram.com/badbucket'
                    target='_blank'
                    rel='noreferrer'>
                    Conner Fawcett's illustration style
                </a>
                , so I tried to emulate some of those techniques in my piece.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterDwarfThumb,
        thumbSize: '1:1',
        img: characterDwarf
    },
    {
        title: 'Forged Character Design',
        id: 19,
        date: { m: 6, d: 22, y: 2019 },
        description: (
            <p>
                This was some character design work for a client that was
                developing their own spin on fantasy tabletop RPGs. They wanted
                some sketches of a character race in their system, dubbed the
                "forged". These are essentially intelligent, fantasy automatons!
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterForgedThumb,
        thumbSize: '1:1',
        img: characterForged
    },
    {
        title: 'Goblin Character Design',
        id: 20,
        date: { m: 6, d: 22, y: 2019 },
        description: (
            <p>
                This was some character design work for a client that was
                developing their own spin on fantasy tabletop RPGs. They wanted
                some sketches of a goblin character race in their systems. These
                little guys absolutely love wealth, and somewhat resemble
                hairless cats.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterGoblinThumb,
        thumbSize: '1:1',
        img: characterGoblin
    },
    {
        title: 'Character Design Sketches',
        id: 21,
        date: { m: 12, d: 4, y: 2020 },
        description: (
            <p>
                This was an exercise where I drew random character headshots. I
                wanted to practice pushing my face shapes, and communicate the
                character's personality through their design.
            </p>
        ),
        skills: ['character-design'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterSketchesThumb,
        thumbSize: '1:1',
        img: characterSketches
    },
    {
        title: 'Honey Heist Character Design',
        id: 22,
        date: { m: 1, d: 2, y: 2020 },
        description: (
            <p>
                I played a game of the RPG{' '}
                <a
                    href='https://gshowitt.itch.io/honey-heist'
                    target='_blank'
                    rel='noreferrer'>
                    Honey Heist
                </a>
                , and absolutely had to draw both my own character (the polar
                bear), and all my friends' characters. And of course, the game
                calls for all the players to throw a heist, so hence the
                criminal lineup!
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: characterHoneyHeistThumb,
        thumbSize: '4:3',
        img: characterHoneyHeist
    },
    {
        title: 'Supreme Courtship Character Art',
        id: 23,
        date: { m: 2, d: 28, y: 2019 },
        description: (
            <p>
                While working on the{' '}
                <Link to='/projects/supreme-courtship'>Supreme Courtship</Link>{' '}
                project, I had the fun challenge of representing real-life
                Supreme Court justices as young, stylish 20-somethings. Many of
                these designs were based on photos of the Supreme Court Justices
                (circa 2016) when they were young. I wanted each character to
                have a unique silhouette and color palette. Can you recognize
                which one here is a young Ruth Bader Ginsburg?
            </p>
        ),
        skills: ['character-design', 'illustration'],
        tools: ['photoshop', 'illustrator'],
        type: 'image',
        thumb: characterSupremeCourtshipThumb,
        thumbSize: '4:3',
        img: characterSupremeCourtship,
        relatedProject: 'supreme-courtship'
    },
    {
        title: 'City Girl',
        id: 24,
        date: { m: 5, d: 13, y: 2017 },
        description: (
            <p>
                I was experimenting with a more painter-ly digital rendering
                style in this piece, and trying out new ways to stylize a
                character.
            </p>
        ),
        skills: ['illustration'],
        tools: ['photoshop'],
        type: 'image',
        thumb: cityGirlThumb,
        thumbSize: '3:4',
        img: cityGirl
    } /*
    {
        title: 'Eyes and Smiles',
        date: { m: 3, d: 19, y: 2019 },
        description: (
            <p>
                Funnily enough, I made this before quarantine! I wanted to
                communicate the feeling of having to appear happy and attentive,
                when really it's just a facade/mask (or in this case, glasses
                too!). This piece means a lot more to me now post-quarantine!
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: eyesAndSmilesThumb,
        thumbSize: '3:4',
        img: eyesAndSmiles
    },*/,
    {
        title: 'Escape from the Bloodkeep Fanart',
        id: 25,
        date: { m: 6, d: 4, y: 2019 },
        description: (
            <p>
                I'm a big fan of Dimension 20, and this was some fanart I made
                for their series{' '}
                <a
                    href='https://dimension20.fandom.com/wiki/Escape_from_the_Bloodkeep'
                    target='_blank'
                    rel='noreferrer'>
                    Escape from the Bloodkeep
                </a>
                . I wanted this to be an epic display of the group's final
                battle!
            </p>
        ),
        skills: ['illustration'],
        tools: ['photoshop'],
        type: 'image',
        thumb: fanartBloodkeepThumb,
        thumbSize: '3:4',
        img: fanartBloodkeep
    },
    {
        title: 'Sailor Moon Fanart',
        id: 26,
        date: { m: 3, d: 21, y: 2020 },
        description: (
            <p>
                There was a viral trend a while ago to redraw a still from the
                90s Sailor Moon anime. Since I absolutely love Sailor moon
                (check out my{' '}
                <Link to='/projects/playing-cards'>
                    Sailor Moon Playing Cards
                </Link>
                ), I knew I had to do my take on this challenge.
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: fanartSailorMoonThumb,
        thumbSize: '4:3',
        img: fanartSailorMoon
    },
    {
        title: 'Overwatch Symmetra Fanart',
        id: 27,
        date: { m: 1, d: 13, y: 2017 },
        description: (
            <p>
                When I first got the game Overwatch and saw it had an Indian
                character, I knew who I was going to be playing as for the
                forseeable future! Symmetra has continued to be my favorite
                Overwatch character, so here's some fanart I made of her.
                Representation matters, and I hope to see many more Indian
                characters in video games!
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate'],
        type: 'image',
        thumb: fanartSymmetraThumb,
        thumbSize: '1:1',
        img: fanartSymmetra
    } /*
    {
        title: 'Diner Menu Game UI',
        id: 28,
        date: { m: 5, d: 1, y: 2019 },
        description: (
            <p>
                This was a design commission I received, where the ask was to
                design a character select menu where it looked and felt like an
                old 50's diner menu.
            </p>
        ),
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: gameUIDesignThumb,
        thumbSize: '4:3',
        img: gameUIDesign
    }, */,
    {
        title: 'Hylian Court Card Back Design',
        id: 28,
        date: { m: 3, d: 21, y: 2012 },
        description: (
            <p>
                This is the design I made for the back side of my{' '}
                <Link to='/projects/playing-cards'>
                    Hylian Court Playing Cards
                </Link>
                . Since this fanart of Legend of Zelda, I wanted the card back
                to evoke some of the design styles of those games. I also based
                the color scheme on that of the Hylian Shield, a very important
                object in the game's lore.
            </p>
        ),
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: hylianCourtCardBackThumb,
        thumbSize: '3:4',
        img: hylianCourtCardBack,
        relatedProject: 'playing-cards'
    },
    {
        title: 'Just Us Games Logo',
        id: 29,
        date: { m: 10, d: 20, y: 2018 },
        description: (
            <p>
                When working on the{' '}
                <Link to='/projects/supreme-courtship'>Supreme Courtship</Link>{' '}
                project, my co-developer and I needed a logo to represent our
                game company. Since it was just two of us in the company, I had
                the idea to have two birds on a telephone line as our logo. I
                feel it portrayed the busy city life we both had while working
                on the game.
            </p>
        ),
        skills: ['design', 'branding'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: logoJustUsGamesThumb,
        thumbSize: '1:1',
        img: logoJustUsGames,
        relatedProject: 'supreme-courtship'
    },
    {
        title: 'Women in Game Development Logo',
        id: 30,
        date: { m: 5, d: 8, y: 2017 },
        description: (
            <p>
                This was a logo I made for the{' '}
                <a
                    href='https://itch.io/jam/sfbawgd-jam-001'
                    target='_blank'
                    rel='noreferrer'>
                    SF Bay Area Women in Game Development
                </a>{' '}
                community. The group did change it a bit (and I believe they
                have also since disbanded), but this is the version I originally
                sent them.
            </p>
        ),
        skills: ['design', 'branding'],
        tools: ['illustrator'],
        type: 'image',
        thumb: logoWomenGameDevThumb,
        thumbSize: '1:1',
        img: logoWomenGameDev
    },
    {
        title: 'Air Mage Card',
        id: 31,
        date: { m: 9, d: 26, y: 2020 },
        description: (
            <p>
                In my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a concept of mage cards, magical tarot-style
                cards which represent a particular type of magic. This is the
                card for air magic, and I thought it fitting to have an
                eastern-style flying dragon on it, since they are often
                associated with air and wind in asian mythologies.
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardAirThumb,
        thumbSize: '3:4',
        img: mageCardAir,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Cold Mage Card',
        id: 32,
        date: { m: 9, d: 18, y: 2020 },
        description: (
            <p>
                In my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a concept of mage cards, magical tarot-style
                cards which represent a particular type of magic. This is the
                card for cold magic, and I wanted to represent a snow fox in an
                icy landscape.
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardColdThumb,
        thumbSize: '3:4',
        img: mageCardCold,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Earth Mage Card (Animated)',
        id: 33,
        date: { m: 6, d: 22, y: 2022 },
        description: (
            <p>
                In my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a concept of mage cards, magical tarot-style
                cards which represent a particular type of magic. This is the
                card for earth magic, and I wanted to represent all the
                different kinds of earth (rock, dirt, gems) in one giant golem
                character. I also animated this to show how the card changes
                when in use by the caster. I had the idea that the earth magic
                "awakens" and so the rocks float.
            </p>
        ),
        skills: ['illustration', 'animation'],
        tools: ['procreate', 'illustrator'],
        type: 'gif',
        thumb: mageCardEarthThumb,
        thumbSize: '1:1',
        img: mageCardEarth,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Fire Mage Card',
        id: 34,
        date: { m: 9, d: 26, y: 2020 },
        description: (
            <p>
                In my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a concept of mage cards, magical tarot-style
                cards which represent a particular type of magic. This is the
                card for fire magic, and I wanted to represent a traditional
                firey dragon. Very much Maleficant vibes!
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardFireThumb,
        thumbSize: '3:4',
        img: mageCardFire,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Flora Mage Card (Animated)',
        id: 35,
        date: { m: 6, d: 17, y: 2022 },
        description: (
            <p>
                In my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a concept of mage cards, magical tarot-style
                cards which represent a particular type of magic. This is the
                card for plant magic, and I wanted to represent a lot of
                different kinds of plants, brought together by a tree spirit. I
                also animated this to show how the card changes when in use by
                the caster. I had the idea that the flora magic "awakens" and so
                the flowers bloom.
            </p>
        ),
        skills: ['illustration', 'animation'],
        tools: ['procreate', 'illustrator'],
        type: 'gif',
        thumb: mageCardFloraThumb,
        thumbSize: '1:1',
        img: mageCardFlora,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'Water Mage Card',
        id: 36,
        date: { m: 9, d: 26, y: 2020 },
        description: (
            <p>
                In my{' '}
                <Link to='/projects/age-of-the-mage'>Age of the Mage</Link>{' '}
                project, I have a concept of mage cards, magical tarot-style
                cards which represent a particular type of magic. This is the
                card for water magic, and what better to represent water than a
                mermaid?
            </p>
        ),
        skills: ['illustration'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: mageCardWaterThumb,
        thumbSize: '3:4',
        img: mageCardWater,
        relatedProject: 'age-of-the-mage'
    },
    {
        title: 'MakeCode Characters',
        id: 37,
        date: { m: 6, d: 7, y: 2020 },
        description: (
            <p>
                While working at{' '}
                <Link to='/resume#MakeCode'>Microsoft MakeCode</Link>, we wanted
                to create fun, likeable mascots for some marketing we were
                doing. MakeCode is known for making games that run on a variety
                of cute hand-held devices, so I definitely wanted to incorporate
                those into the mascots. I was heavily inspired by the{' '}
                <a
                    href='https://adventuretime.fandom.com/wiki/BMO'
                    target='_blank'
                    rel='noreferrer'>
                    character BMO from Adventure Time
                </a>
                !
            </p>
        ),
        skills: ['illustration', 'character-design', 'branding'],
        tools: ['procreate', 'illustrator'],
        type: 'image',
        thumb: makecodeThumb,
        thumbSize: '4:3',
        img: makecode
    },
    {
        title: 'Moon Prism Card Back Design',
        id: 38,
        date: { m: 3, d: 27, y: 2015 },
        description: (
            <p>
                This is the design I made for the back side of my{' '}
                <Link to='/projects/playing-cards'>
                    Moon Prism Playing Cards
                </Link>{' '}
                . Since this is a fanart deck of Sailor Moon characters, I
                wanted the card back to evoke some of the design styles of the
                Sailor Moon manga and anime. Thus the design was heavily
                inspired by the first Sailor Moon transformation sequence, with
                the transformation ribbons highlighted here.
            </p>
        ),
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: moonPrismCardBackThumb,
        thumbSize: '3:4',
        img: moonPrismCardBack,
        relatedProject: 'playing-cards'
    } /*
    {
        title: 'Moon Prism Sailor Venus Card',
        date: { m: 6, d: 31, y: 2017 },
        description: (
            <p>
                This is the design I made for the Jack of Hearts in my{' '}
                <Link to='/projects/playing-cards'>
                    Moon Prism Playing Cards
                </Link>{' '}
                . Sailor Venus felt like a perfect fit for Jack of Hearts, and I
                had a lot of fun interpreting what she would look like in my
                playing card style. While this is far removed from the typical
                anime style of Sailor Moon, I hope I captured her essence here!
            </p>
        ),
        skills: ['illustration'],
        tools: ['illustrator'],
        type: 'image',
        thumb: moonPrismJackThumb,
        thumbSize: '3:4',
        img: moonPrismJack,
        relatedProject: 'playing-cards'
    },*/,
    {
        title: 'One Last Job Crew Card Design',
        id: 39,
        date: { m: 6, d: 31, y: 2017 },
        description: (
            <p>
                My client had a card game they were developing that they needed
                some designs for, and this is one of the card back designs I
                created. The game is heist themed, so I wanted to include a lot
                of heist-like elements. Of course, every good heist needs a bank
                blueprint, so that's where I started from!
            </p>
        ),
        skills: ['design', 'branding'],
        tools: ['illustrator'],
        type: 'image',
        thumb: oneLastJobCrewThumb,
        thumbSize: '3:4',
        img: oneLastJobCrew
    },
    {
        title: 'One Last Job Location Card Design',
        id: 40,
        date: { m: 6, d: 31, y: 2017 },
        description: (
            <p>
                My client had a card game they were developing that they needed
                some designs for, and this is one of the card back designs I
                came up with. The game is heist themed, and this card is for the
                place the players will steal from. I wanted to include a lot of
                heist-like elements, focusing on the intel gathering phase. And
                every good stake-out needs a map of the area, and some polaroid
                photos, so that's what I started with! All the other pieces used
                in this were designed and made by me as well.
            </p>
        ),
        skills: ['design'],
        tools: ['illustrator'],
        type: 'image',
        thumb: oneLastJobLocationThumb,
        thumbSize: '4:3',
        img: oneLastJobLocation
    },
    {
        title: '"Luminesence" Portrait',
        id: 41,
        date: { m: 8, d: 19, y: 2016 },
        description: (
            <p>
                This is a digital portrait painting I made from a{' '}
                <a
                    href='https://www.google.com/search?q=Pierre+Gonnord+Kevin&tbm=isch&ved=2ahUKEwjY5erno7f8AhUqHzQIHeBOAqsQ2-cCegQIABAA&oq=Pierre+Gonnord+Kevin&gs_lcp=CgNpbWcQAzIECCMQJzIECCMQJ1AAWABgpQRoAHAAeACAATOIATOSAQExmAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=0Fm6Y9i9Mqq-0PEP4J2J2Ao&bih=1041&biw=1437&rlz=1C5CHFA_enUS913US913#imgrc=tIAD2ecGuDvpZM'
                    target='_blank'
                    rel='noreferrer'>
                    reference photo
                </a>{' '}
                as part of series of portraits. I wanted to practice capturing
                likenesses as well as a variety of digital painting techniques.
                In this piece, I really enjoyed working on the fine details in
                the hair. You can actually watch a{' '}
                <a
                    href='https://www.youtube.com/watch?v=e-CMBjLOnC8'
                    target='_blank'
                    rel='noreferrer'>
                    process video
                </a>{' '}
                for this piece I made and posted on my old youtube account!
            </p>
        ),
        skills: ['illustration'],
        tools: ['photoshop'],
        type: 'image',
        thumb: portrait01Thumb,
        thumbSize: '3:4',
        img: portrait01
    },
    {
        title: '"Pretty in Pink" Portrait',
        id: 42,
        date: { m: 9, d: 2, y: 2016 },
        description: (
            <p>
                This is a digital portrait painting I made from a{' '}
                <a
                    href='https://www.google.com/search?q=sakura+heffron+photography&&tbm=isch&ved=2ahUKEwiZxtLuorf8AhUWFzQIHWSCCckQ2-cCegQIABAA&oq=sakura+heffron+photography&gs_lcp=CgNpbWcQAzoECCMQJzoFCAAQgAQ6BwgAEIAEEBhQhgNY7yZg0ShoBHAAeACAAT2IAc8GkgECMTeYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=0li6Y5mVKZau0PEP5ISmyAw&bih=1041&biw=1437#imgrc=2pfuOXfzHAteVM'
                    target='_blank'
                    rel='noreferrer'>
                    reference photo
                </a>{' '}
                as part of series of portraits. I wanted to practice capturing
                likenesses as well as a variety of digital painting techniques.
                In this piece, I really enjoyed working with different fabric
                textures, like the fur on the jacket.
            </p>
        ),
        skills: ['illustration'],
        tools: ['photoshop'],
        type: 'image',
        thumb: portrait02Thumb,
        thumbSize: '3:4',
        img: portrait02
    },
    {
        title: '"Neon" Portrait',
        id: 43,
        date: { m: 2, d: 10, y: 2016 },
        description: (
            <p>
                This is a digital portrait painting I made from a{' '}
                <a
                    href='https://www.google.com/search?q=aiyana+lewis+photography&tbm=isch&ved=2ahUKEwjX8dCCo7f8AhVaLjQIHXVxDIsQ2-cCegQIABAA&oq=aiyana+lewis+photography&gs_lcp=CgNpbWcQAzoECCMQJzoFCAAQgAQ6BAgAEEM6BggAEAgQHlCxAljrEGDKEmgAcAB4AIABX4gBnwWSAQIxM5gBAKABAaoBC2d3cy13aXotaW1nwAEB&sclient=img&ei=_Fi6Y9eDJNrc0PEP9eKx2Ag&bih=1041&biw=1437#imgrc=AOdt_gPDAC9v9M'
                    target='_blank'
                    rel='noreferrer'>
                    reference photo
                </a>{' '}
                as part of series of portraits. I wanted to practice capturing
                likenesses as well as a variety of digital painting techniques.
                In this piece, I had a fun time making the curls in the hair and
                the definition on the lips and nose.
            </p>
        ),
        skills: ['illustration'],
        tools: ['photoshop'],
        type: 'image',
        thumb: portrait03Thumb,
        thumbSize: '3:4',
        img: portrait03
    },
    {
        title: 'Supreme Courtship Trailer',
        id: 44,
        date: { m: 2, d: 3, y: 2019 },
        description: (
            <p>
                This is the final game trailer I made for the{' '}
                <Link to='/projects/supreme-courtship'>Supreme Courtship</Link>{' '}
                project. There were a lot of pieces involved in making this,
                from collecting all the game snippets (all the game UI is also
                designed by me), to directing the music production, to editing
                and putting it all together! Doesn't this make you want to be a
                Justice?
            </p>
        ),
        skills: ['art-direction', 'animation', 'design', 'branding'],
        tools: ['after-effects', 'illustrator', 'photoshop'],
        type: 'video',
        thumb: supremeCourtshipTrailerThumb,
        thumbSize: '16:9',
        img: supremeCourtshipTrailer,
        poster: supremeCourtshipTrailerPoster,
        relatedProject: 'supreme-courtship'
    },
    {
        title: 'Hand Study',
        id: 45,
        date: { m: 3, d: 4, y: 2020 },
        description: <p>Still life sketches of some lovely hands!</p>,
        skills: [],
        tools: ['procreate'],
        type: 'image',
        thumb: sketchHandsThumb,
        thumbSize: '1:1',
        img: sketchHands
    },
    {
        title: 'Feet Study',
        id: 46,
        date: { m: 3, d: 6, y: 2020 },
        description: <p>Still life sketches of some lovely feet!</p>,
        skills: [],
        tools: ['procreate'],
        type: 'image',
        thumb: sketchFeetThumb,
        thumbSize: '1:1',
        img: sketchFeet
    },
    {
        title: 'Supreme Courtship Logo',
        id: 47,
        date: { m: 11, d: 10, y: 2018 },
        description: (
            <p>
                One of the early things I designed for the{' '}
                <Link to='/projects/supreme-courtship'>Supreme Courtship</Link>{' '}
                project was its logo. This was such an important piece to help
                set the tone and aesthetic of the game. Since this is a game
                about the government, I wanted the logo design to represent a
                government department seal, much like the actual seal of the
                Supreme Court. But I also wanted to make it clear that this was
                a fun, flirty game, so I took a lot of elements from romantic
                dating simulator games as well.
            </p>
        ),
        skills: ['design', 'branding'],
        tools: ['illustrator'],
        type: 'image',
        thumb: supremeCourtshipLogoThumb,
        thumbSize: '1:1',
        img: supremeCourtshipLogo,
        relatedProject: 'supreme-courtship'
    },
    {
        title: 'Supreme Courtship Cinematic',
        id: 48,
        date: { m: 8, d: 4, y: 2018 },
        description: (
            <p>
                In the{' '}
                <Link to='/projects/supreme-courtship'>Supreme Courtship</Link>{' '}
                project, we wanted to start our game off with a murder mystery
                cinematic. In this scene, Justice Scalia is murdered by an
                unknown woman and her accomplice, setting up the rest of the
                game where you take Scalia's place! We wanted this scene to give
                off a noir detective feel. Check out the final in-game cinematic
                on the project page.
            </p>
        ),
        skills: ['animation', 'storyboarding'],
        tools: ['photoshop'],
        type: 'gif',
        thumb: supremeCourtshipStoryboardThumb,
        thumbSize: '4:3',
        img: supremeCourtshipStoryboard,
        relatedProject: 'supreme-courtship'
    },
    {
        title: 'MakeCode Hour of Code Animation',
        id: 49,
        date: { m: 10, d: 28, y: 2021 },
        description: (
            <p>
                While working at <a href='/resume.pdf'>Microsoft MakeCode</a>, I
                would often make pixelart for events. Hour of Code was one of
                these, and I wanted an animated image to show off the game we
                were marketing; a fire-fighting simluator where you flew a plane
                around and dowsed forest fires. You can see this image in action
                on the{' '}
                <a
                    href='https://arcade.makecode.com/hour-of-code-2021'
                    target='_blank'
                    rel='noreferrer'>
                    webpage for the event
                </a>
                . By the way, that webpage was also designed and coded by me!
            </p>
        ),
        skills: ['illustration', 'animation'],
        tools: ['aseprite'],
        type: 'gif',
        thumb: makecodeHourOfCodeThumb,
        thumbSize: '4:3',
        img: makecodeHourOfCode
    }
];

let randomizedGallery = dataGallery.sort(() => Math.random() - 0.5);

export default randomizedGallery;
