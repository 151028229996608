import React, { useState } from 'react';
import Footer from '../../components/footer';
import Nav from '../../components/nav';
import {
    Project,
    ProjectGallery,
    ProjectImagesSideBySide,
    ProjectLink,
    ProjectLinks,
    ProjectSectionSideBySide,
    ProjectSectionStacked
} from '../../components/projects/project';

import {
    dataHylianCourt,
    dataMoonPrism,
    dataRebelEmpire
} from '../../_data/data-playing-cards';

import decks from '../../_img/projects/playing-cards/decks.png';
import packages from '../../_img/projects/playing-cards/packages.png';
import booth from '../../_img/projects/playing-cards/booth.png';
import useDocumentTitle from '../../util/use-document-title';

function PlayingCards(props) {
    useDocumentTitle('Playing Cards');

    let sections = [
        { title: 'The Idea', id: 'idea' },
        { title: 'Hylian Court', id: 'hylian-court' },
        { title: 'Moon Prism', id: 'moon-prism' },
        { title: 'Rebel Empire', id: 'rebel-empire' },
        { title: 'Printing and Selling', id: 'printing-and-selling' },
        { title: 'Links', id: 'links' }
    ];

    const random = () => {
        return Math.random() * 4 - 2;
    };

    const [hcLightbox, setHCLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    const [mpLightbox, setMPLightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    const [reLightbox, setRELightbox] = useState({
        id: null,
        item: null,
        ind: null
    });

    return (
        <div className='page'>
            <Nav />
            <Project
                title='Playing Cards'
                sections={sections}
                description='Designing and illustrating fan art playing card decks'>
                <ProjectSectionSideBySide
                    id={sections[0].id}
                    title={sections[0].title}
                    titleOn='right'
                    left={
                        <img
                            className='rotated'
                            style={{
                                transform: 'rotate(' + random() + 'deg)'
                            }}
                            src={decks}
                            alt={sections[0].title}
                        />
                    }
                    right={
                        <p>
                            A drawing I made of Link from The Legend of Zelda as
                            the Jack of Spades went{' '}
                            <a
                                href='https://www.reddit.com/r/gaming/comments/1aayvh/im_designing_a_zelda_themed_deck_of_playing_cards/'
                                target='_blank'
                                rel='noreferrer'>
                                viral on reddit
                            </a>
                            , motivating me to make complete playing card decks
                            of some of my favorite fandoms. I ran a small art
                            business producing and selling these playing card
                            decks for many years, and it's always been a very
                            fond project of mine!
                        </p>
                    }
                />

                <ProjectGallery
                    id={sections[1].id}
                    title={sections[1].title}
                    description={
                        <p>
                            My first deck came with a lot of experimentation. I
                            was heavily inspired by traditional playing card
                            design, adding my own flair with unique icons for
                            the different suits.
                        </p>
                    }
                    items={dataHylianCourt}
                    lightbox={hcLightbox}
                    setLightbox={setHCLightbox}
                />

                <ProjectGallery
                    id={sections[2].id}
                    title={sections[2].title}
                    description={
                        <p>
                            I went in a much more cartoon/anime style for this
                            deck, rightly so as it is based on Sailor Moon! I
                            also designed three versions for each royal card, so
                            that I could include more of my favorite characters
                            and favorite outfits.
                        </p>
                    }
                    items={dataMoonPrism}
                    lightbox={mpLightbox}
                    setLightbox={setMPLightbox}
                />

                <ProjectGallery
                    id={sections[3].id}
                    title={sections[3].title}
                    description={
                        <p>
                            In my third and final deck, based on Star Wars, I
                            went back to a more traditional style with a limited
                            set of colors and traditional suits. It was a lot of
                            fun capturing the likeness of the actors in this
                            style!
                        </p>
                    }
                    items={dataRebelEmpire}
                    lightbox={reLightbox}
                    setLightbox={setRELightbox}
                />

                <ProjectSectionStacked
                    id={sections[4].id}
                    title={sections[4].title}
                    top={
                        <p>
                            I ran a small art business out of my college dorm
                            for a few years, printing, packaging, and mailing
                            out decks to people all over the world! I also sold
                            the decks at many nerdy conventions, under the art
                            alias "Soren Kalla".
                        </p>
                    }
                    bottom={
                        <ProjectImagesSideBySide>
                            <img src={packages} alt={sections[4].title} />
                            <img src={booth} alt={sections[4].title} />
                        </ProjectImagesSideBySide>
                    }
                />

                <ProjectLinks>
                    <ProjectLink href='https://www.gameinformer.com/b/news/archive/2014/02/17/legend-of-zelda-turned-into-playing-cards-by-artist.aspx'>
                        GameInformer Article
                    </ProjectLink>
                    <ProjectLink
                        href='https://sidehustleschool.com/episode/325/'
                        message=''>
                        SideHustle Episode
                    </ProjectLink>
                </ProjectLinks>
            </Project>

            <Footer className='project-footer' />
        </div>
    );
}

export default PlayingCards;
