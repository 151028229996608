import React from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

import { TextBtn } from '../buttons';

function SectionContact(props) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async data => {
        const { name, email, message } = data;
        try {
            document.getElementById('contact').classList.add('sending');
            const templateParams = {
                name,
                email,
                message
            };
            await emailjs.send(
                process.env.REACT_APP_MAIL_SERVICE_ID,
                process.env.REACT_APP_MAIL_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_MAIL_USER_ID
            );
            reset();
            document.getElementById('contact').classList.remove('sending');
            document.getElementById('contact').classList.add('success');
            setTimeout(() => {
                document.getElementById('contact').classList.remove('success');
            }, 1000);
        } catch (e) {
            console.log(e);
            document.getElementById('contact').classList.remove('sending');
            document.getElementById('contact').classList.add('error');
            setTimeout(() => {
                document.getElementById('contact').classList.remove('error');
            }, 2000);
        }
    };
    return (
        <section className='section-contact' id='contact'>
            <div className='container'>
                <h2>Contact Me</h2>
                <div className='sent-message'>
                    <div className='graphic' />
                    <p className='sending-msg'>Message sending...</p>
                    <p className='success-msg'>Message sent!</p>
                    <p className='error-msg'>
                        Message failed to send. Try again later!
                    </p>
                </div>
                <form
                    id='contact-form'
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate>
                    <div className='input-name'>
                        <input
                            type='text'
                            name='name'
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: 'Please enter your name'
                                },
                                maxLength: {
                                    value: 30,
                                    message: 'Please use 30 characters or less'
                                }
                            })}
                            className=''
                            placeholder='Name'
                        />
                        {errors.name && (
                            <span className='error'>
                                <i className='fa-solid fa-exclamation-triangle' />{' '}
                                {errors.name.message}
                            </span>
                        )}
                    </div>

                    <div className='input-email'>
                        <input
                            type='email'
                            name='email'
                            className=''
                            {...register('email', {
                                required: true,
                                pattern:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                            placeholder='Email'
                        />
                        {errors.email && (
                            <span className='error'>
                                <i className='fa-solid fa-exclamation-triangle' />{' '}
                                Please enter a valid email address
                            </span>
                        )}
                    </div>

                    <div className='input-message'>
                        <textarea
                            rows={7}
                            name='message'
                            className=''
                            {...register('message', {
                                required: true
                            })}
                            placeholder='Message'></textarea>

                        {errors.message && (
                            <span className='error'>
                                <i className='fa-solid fa-exclamation-triangle' />{' '}
                                Please enter a message
                            </span>
                        )}
                    </div>

                    <TextBtn
                        type='submit'
                        text='send'
                        rightIcon='fa-solid fa-paper-plane'
                    />
                </form>
            </div>
        </section>
    );
}

export default SectionContact;
